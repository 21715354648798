import { UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { makeObservable, observable } from 'mobx';

import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';
import { ListModel } from '@/shared/model';
import { UploadFileModel } from '@/shared/model/form/UploadFileModel';
import { Nullable } from '@/shared/types/values';
import { fileSizeValidator } from '@/shared/utils/validators';

import { ArbitraryDocOptionsModel } from './ArbitraryDocOptionsModel';
import { ArbitraryDocsFormModel, ArbitraryDocsFormModelParams } from './ArbitraryDocsFormModel';
import { DocumentModel } from './DocumentModel';

type Params = ArbitraryDocsFormModelParams & {
  arbitraryDocsOptions: ListModel<ArbitraryDocOptionsModel>;
};

export class ArbitraryDocsEditFormModel extends ArbitraryDocsFormModel {
  private _lastRemovedUid: Nullable<string> = null;

  constructor({ arbitraryDocsOptions, ...params }: Params) {
    super(params);

    this._arbitraryDocsOptions = arbitraryDocsOptions;

    makeObservable<this, '_lastRemovedUid'>(this, {
      _lastRemovedUid: observable,
    });
  }

  onRemove(file: UploadFile) {
    const fileList = this.arbitraryDocs.value.filter((f) => f.uid !== file.uid);
    this.arbitraryDocs.change(fileList);
    this._lastRemovedUid = file.uid;
  }

  onChange({ file, fileList }: UploadChangeParam) {
    fileList = fileList.map((file) => {
      const error = fileSizeValidator([file]);

      return {
        ...file,
        uid: this._lastRemovedUid ?? file.uid,
        error,
        status: error ? 'error' : 'done',
      };
    });

    this.arbitraryDocs.change(fileList);

    if (this._lastRemovedUid) {
      this._lastRemovedUid = null;
    } else {
      this._arbitraryDocsOptions.addEntity({
        entity: new ArbitraryDocOptionsModel({ rootStore: this.tradeWorkflowStore.rootStore }),
        key: file.uid,
      });
    }
  }

  static fromJsonEdit(
    tradeWorkflowStore: ITradeWorkflowStore,
    files: DocumentModel[],
    disabled: boolean = false,
  ): ArbitraryDocsEditFormModel {
    const fileList = files.map((file) => file.file);

    const arbitraryDocs = new UploadFileModel({
      initialValue: fileList,
      maxCount: files.length,
      disabled,
    });

    const arbitraryDocsOptions = new ListModel<ArbitraryDocOptionsModel>();

    if (files) {
      arbitraryDocs.change(fileList);
      arbitraryDocsOptions.fillByRawData(
        files,
        (file) => ({
          entity: new ArbitraryDocOptionsModel({ docModel: file, rootStore: tradeWorkflowStore.rootStore }),
          key: String(file.id),
        }),
        true,
      );
    }

    return new ArbitraryDocsEditFormModel({
      arbitraryDocs,
      arbitraryDocsOptions,
      disabled,
      tradeWorkflowStore,
    });
  }
}
