import { Button, ButtonProps } from 'antd';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks/useTranslation';
import { TranslationNode } from '@/shared/types/localization';

export type CustomButtonProps = Omit<ButtonProps, 'children'> & {
  children: TranslationNode;
};

const CustomButton: React.FC<CustomButtonProps> = ({ children, ...props }) => {
  const { t } = useTypedTranslation();

  const childrenNode = React.useMemo(
    () => (typeof children === 'function' ? <span>{children(t)}</span> : children),
    [children, t],
  );

  return <Button {...props}>{childrenNode}</Button>;
};

export default CustomButton;
