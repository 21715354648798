import { DatePicker } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks/useTranslation';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { renderTranslationString } from '@/shared/utils/renderTranslation';

import { FormItem } from '../FormItem';

type FormDatePickerProps = {
  className?: string;
  model: DatePickerModel;
  disabled?: boolean;
};

const DateFormat = 'DD-MM-YYYY';

const FormDatePicker: React.FC<FormDatePickerProps> = ({ className, model, disabled }) => {
  const { t } = useTypedTranslation();

  return (
    <FormItem className={className} ref={model.ref} model={model}>
      <DatePicker
        style={{ width: '100%' }}
        {...model.props}
        placeholder={renderTranslationString(model.placeholder, t)}
        disabled={model.props.disabled || disabled}
        format={{ format: DateFormat, type: 'mask' }}
      />
    </FormItem>
  );
};

export default observer(FormDatePicker);
