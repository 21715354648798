import { StageType, SupplierFinalizationStageDataServer, SupplierFinalizationStep } from '@/entities/stage';
import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';

import { BaseManagerStageModel } from '../../BaseManagerStageModel';
import { BaseStageModel, BaseStageModelParams } from '../../BaseStageModel';
import { WaitingFieldsModel } from '../../fieldsModel/WaitingFieldsModel';
import { WaitingFieldsWithFilesModel } from '../../fieldsModel/WaitingFieldsWithFilesModel';

import { mapSupplierStepToNumber, TradeCloseSupplierDocs, tradeCloseSupplierDocsOptions } from './config';

type Params = BaseStageModelParams<SupplierFinalizationStep> & {
  cargoArrivalFields: WaitingFieldsModel<SupplierFinalizationStep>;
  tradeCloseSupplierDocsFields: WaitingFieldsWithFilesModel<SupplierFinalizationStep, TradeCloseSupplierDocs>;
};

export class SupplierFinalizationStageModel extends BaseStageModel<StageType.finalization, SupplierFinalizationStep> {
  cargoArrivalFields: WaitingFieldsModel<SupplierFinalizationStep>;
  tradeCloseSupplierDocsFields: WaitingFieldsWithFilesModel<SupplierFinalizationStep, TradeCloseSupplierDocs>;

  constructor({ cargoArrivalFields, tradeCloseSupplierDocsFields, ...params }: Params) {
    super({
      type: StageType.finalization,
      ...params,
    });

    this.cargoArrivalFields = cargoArrivalFields;
    this.tradeCloseSupplierDocsFields = tradeCloseSupplierDocsFields;
  }

  get isCargoArrived(): boolean {
    return this.step !== SupplierFinalizationStep.cargoArrival;
  }

  get isTradeCloseDocsUploaded(): boolean {
    return Boolean(this.tradeCloseSupplierDocsFields.files?.every(({ file }) => file.isUploaded));
  }

  get isFinished(): boolean {
    return this.isTradeCloseDocsUploaded || this.step === SupplierFinalizationStep.finish;
  }

  get currentStep(): number {
    return mapSupplierStepToNumber(this.step);
  }

  get steps(): SupplierFinalizationStep[] {
    return [
      SupplierFinalizationStep.cargoArrival,
      SupplierFinalizationStep.tradeCloseSupplierDocs,
      SupplierFinalizationStep.finish,
    ];
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: {
    dataServer: SupplierFinalizationStageDataServer;
    tradeWorkflowStore: ITradeWorkflowStore;
  }): SupplierFinalizationStageModel {
    return new SupplierFinalizationStageModel({
      cargoArrivalFields: WaitingFieldsModel.fromJson({
        step: SupplierFinalizationStep.cargoArrival,
        tradeWorkflowStore,
      }),
      tradeCloseSupplierDocsFields: WaitingFieldsWithFilesModel.fromJson({
        data: dataServer.steps[SupplierFinalizationStep.tradeCloseSupplierDocs],
        options: tradeCloseSupplierDocsOptions,
        step: SupplierFinalizationStep.tradeCloseSupplierDocs,
        tradeWorkflowStore,
      }),
      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
