import { SizeType } from 'antd/es/config-provider/SizeContext';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { InputModel } from '@/shared/model/form/InputModel';

import { CustomInput } from '../CustomInput';
import { FormItem } from '../FormItem';

type FormInputProps = {
  className?: string;
  model: InputModel;
  size?: SizeType;
  addonAfter?: React.ReactNode;
  addonBefore?: React.ReactNode;
  suffix?: React.ReactNode;
  readOnly?: boolean;
  disabled?: boolean;
};

const FormInput = ({ className, model, disabled, ...props }: FormInputProps): React.ReactElement<FormInputProps> => {
  return (
    <FormItem className={className} ref={model.ref} model={model}>
      <CustomInput {...model.props} {...props} disabled={model.props.disabled || disabled} />
    </FormItem>
  );
};

export default observer(FormInput);
