import { ApiRequest } from '@kts-front/call-api';
import { action, makeObservable } from 'mobx';

import { MessageType } from '@/entities/message';
import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel } from '@/shared/model';
import { UploadFileModel, UploadFileModelParams } from '@/shared/model/form/UploadFileModel';
import { Nullable } from '@/shared/types/values';

import { DocumentFileType, UploadDocumentsPayload } from '../types';

type ModelParams = UploadFileModelParams & {
  tradeWorkflowStore: ITradeWorkflowStore;
  docType: DocumentFileType;
};

export class UploadOptionalFileModel extends UploadFileModel {
  private readonly _docType: DocumentFileType;
  private readonly _uploadRequest: ApiRequest<unknown>;

  readonly loadingStage = new LoadingStageModel();
  readonly tradeWorkflowStore: ITradeWorkflowStore;

  constructor({ docType, tradeWorkflowStore, ...params }: ModelParams) {
    super(params);

    this._uploadRequest = apiStore.createRequest({
      method: 'POST',
      url: apiUrls.trade.upload(tradeWorkflowStore.tradeId),
    });

    this._docType = docType;
    this.tradeWorkflowStore = tradeWorkflowStore;

    makeObservable(this, {
      uploadFile: action.bound,
    });
  }

  private _toJson(): Nullable<UploadDocumentsPayload> {
    const file = this.value.length ? this.value[0].originFileObj : null;

    if (!file) {
      return null;
    }

    return {
      files: [file],
      doc_types: [this._docType],
    };
  }

  async uploadFile(): Promise<void> {
    const payload = this._toJson();

    if (this.loadingStage.isLoading || !payload) {
      return;
    }

    this.loadingStage.loading();

    const response = await this._uploadRequest.call({
      multipartFormData: true,
      data: payload,
    });

    if (response.isError) {
      this.loadingStage.error();
      this.tradeWorkflowStore.rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.uploadDocError', { ns: 'file', count: this._value.length }),
      });

      return;
    }

    this.tradeWorkflowStore.rootStore.notificationsStore.addNotification({
      type: MessageType.success,
      message: (t) => t('messages.uploadDocSuccess', { ns: 'file', count: this._value.length }),
    });

    this.loadingStage.success();

    this.tradeWorkflowStore.stageModel.optionalFields.reloadOptionalFields();
  }
}
