import { Collapse, CollapseProps, Flex } from 'antd';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks/useTranslation';

type StageContentProps = {
  className?: string;
  requiredSteps: React.ReactNode;
  optionalSteps?: React.ReactNode;
};

enum StepsKey {
  REQUIRED = 1,
  OPTIONAL = 2,
}

const StageContentTablet: React.FC<StageContentProps> = ({ className, requiredSteps, optionalSteps }) => {
  const { t } = useTypedTranslation('shared');

  if (optionalSteps) {
    const items: CollapseProps['items'] = [
      {
        key: StepsKey.REQUIRED,
        label: t('components.StageContentTablet.labels.required'),
        children: requiredSteps,
      },
      {
        key: StepsKey.OPTIONAL,
        label: t('components.StageContentTablet.labels.optional'),
        children: (
          <Flex vertical gap={12}>
            {optionalSteps}
          </Flex>
        ),
      },
    ];

    return <Collapse className={className} defaultActiveKey={[StepsKey.REQUIRED]} items={items} />;
  }

  return requiredSteps;
};

export default React.memo(StageContentTablet);
