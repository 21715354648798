import {
  ManagerFinalizationAction,
  ManagerFinalizationStageDataServer,
  ManagerFinalizationStep,
  ManagerTransactionAction,
  StageType,
} from '@/entities/stage';
import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';

import { BaseManagerStageModel, BaseManagerStageModelParams } from '../../BaseManagerStageModel';
import { ConfirmationFieldsModel } from '../../fieldsModel/ConfirmationFieldsModel';
import { CustomerPaymentFieldsModel } from '../fieldsModel/CustomerPaymentFieldsModel';
import { TradeCloseCustomerDocsFieldsModel } from '../fieldsModel/TradeCloseCustomerDocsFieldsModel';

import { mapManagerStepToNumber } from './config';

type Params = BaseManagerStageModelParams<ManagerFinalizationStep> & {
  cargoDeliveredFields: ConfirmationFieldsModel<ManagerFinalizationStep, ManagerFinalizationAction>;
  tradeCloseCustomerDocsFields: TradeCloseCustomerDocsFieldsModel<ManagerFinalizationStep, ManagerFinalizationAction>;
  customerPaymentFields: CustomerPaymentFieldsModel<ManagerFinalizationStep, ManagerFinalizationAction>;
};

export class ManagerFinalizationStageModel extends BaseManagerStageModel<
  StageType.finalization,
  ManagerFinalizationStep
> {
  readonly cargoDeliveredFields: ConfirmationFieldsModel<ManagerFinalizationStep, ManagerFinalizationAction>;
  readonly tradeCloseCustomerDocsFields: TradeCloseCustomerDocsFieldsModel<
    ManagerFinalizationStep,
    ManagerFinalizationAction
  >;
  readonly customerPaymentFields: CustomerPaymentFieldsModel<ManagerFinalizationStep, ManagerFinalizationAction>;

  constructor({ cargoDeliveredFields, tradeCloseCustomerDocsFields, customerPaymentFields, ...params }: Params) {
    super({
      type: StageType.finalization,
      ...params,
    });

    this.cargoDeliveredFields = cargoDeliveredFields;
    this.tradeCloseCustomerDocsFields = tradeCloseCustomerDocsFields;
    this.customerPaymentFields = customerPaymentFields;
  }

  get currentStep(): number {
    return mapManagerStepToNumber(this.step);
  }

  get steps(): ManagerFinalizationStep[] {
    return [
      ManagerFinalizationStep.cargoDelivered,
      ManagerFinalizationStep.tradeCloseCustomerDocs,
      ManagerFinalizationStep.customerPayments,
    ];
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: {
    dataServer: ManagerFinalizationStageDataServer;
    tradeWorkflowStore: ITradeWorkflowStore;
  }): ManagerFinalizationStageModel {
    return new ManagerFinalizationStageModel({
      cargoDeliveredFields: ConfirmationFieldsModel.fromJson({
        data: dataServer.steps[ManagerFinalizationStep.cargoDelivered]?.delivered,
        step: ManagerFinalizationStep.cargoDelivered,
        approveAction: ManagerFinalizationStep.cargoDelivered,
        tradeWorkflowStore,
      }),
      tradeCloseCustomerDocsFields: TradeCloseCustomerDocsFieldsModel.fromJson({
        data: dataServer.steps[ManagerFinalizationStep.tradeCloseCustomerDocs],
        step: ManagerFinalizationStep.tradeCloseCustomerDocs,
        approveAction: ManagerFinalizationStep.tradeCloseCustomerDocs,
        tradeWorkflowStore,
      }),
      customerPaymentFields: CustomerPaymentFieldsModel.fromJson({
        data: dataServer.steps[ManagerFinalizationStep.customerPayments],
        step: ManagerFinalizationStep.customerPayments,
        approveAction: ManagerTransactionAction.finish,
        tradeWorkflowStore,
      }),
      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
