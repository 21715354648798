import { DocumentFileType } from '@/entities/file';
import { CustomerFinalizationStep } from '@/entities/stage/types';
import { Nullable } from '@/shared/types/values';

import { DocTypesOptions } from '../../fieldsModel/WaitingFieldsWithFilesModel';

export type TradeCloseCustomerDocs =
  | DocumentFileType.signedCustomerAcceptanceCertificate
  | DocumentFileType.signedCustomerAdditionalAgreement;

const T_OPTONS = { ns: 'finalizationStage' } as const;

export const tradeCloseCustomerDocsOptions: DocTypesOptions<TradeCloseCustomerDocs> = [
  {
    docType: DocumentFileType.signedCustomerAcceptanceCertificate,
    description: (t) =>
      t(
        `customer.tradeCloseCustomerDocs.docsDescription.${DocumentFileType.signedCustomerAcceptanceCertificate}`,
        T_OPTONS,
      ),
  },
  {
    docType: DocumentFileType.signedCustomerAdditionalAgreement,
    description: (t) =>
      t(
        `customer.tradeCloseCustomerDocs.docsDescription.${DocumentFileType.signedCustomerAdditionalAgreement}`,
        T_OPTONS,
      ),
  },
];

export const mapCustomerStepToNumber = (step: Nullable<CustomerFinalizationStep>): number => {
  switch (step) {
    case CustomerFinalizationStep.cargoDelivery:
      return 0;

    case CustomerFinalizationStep.tradeCloseCustomerDocs:
      return 1;

    case CustomerFinalizationStep.finish:
      return 2;

    default:
      return -1;
  }
};
