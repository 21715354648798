import { MapDataFieldsToNullable } from '@/shared/types/meta';

export enum Packing {
  bag = 'bag',
  plasticPallet = 'plastic_pallet',
}

export enum Incoterms {
  EXW = 'exw',
  FCA = 'fca',
  CPT = 'cpt',
  CIP = 'cip',
  DAT = 'dat',
  DAP = 'dap',
  DDP = 'ddp',
  FAS = 'fas',
  FOB = 'fob',
  CFR = 'cfr',
  CIF = 'cif',
}

export type InvoiceDocPayload = {
  date: string;
  country_of_manufacture_id?: number;
  flight_number: string;
  packing: Packing;
  incoterms: Incoterms;
  signatory_id: number;
};

export type InvoiceDocResponse = Partial<MapDataFieldsToNullable<InvoiceDocPayload>>;
