import { SizeType } from 'antd/es/config-provider/SizeContext';
import cn from 'classnames';
import * as React from 'react';

import CustomButton from '../../CustomButton';
import { BaseButtonProps } from '../types';

import s from './ApproveButton.module.scss';

type ApproveButtonProps = BaseButtonProps & {
  size?: SizeType;
  icon?: React.ReactNode;
};

const ApproveButton: React.FC<ApproveButtonProps> = ({
  className,
  text = (t) => t('buttons.approve', { ns: 'shared' }),
  size = 'small',
  ...props
}) => {
  return (
    <CustomButton className={cn(s.button, className)} type="primary" size={size} {...props}>
      {text}
    </CustomButton>
  );
};

export default React.memo(ApproveButton);
