export const createTrade = {
  en: {
    fields: {
      supplier: 'Supplier',
      customer: 'Customer',
      legalCompanyFrom: 'Legal entity (from who buy)',
      legalCompanyTo: 'Legal entity (from who sell)',
      productType: 'Product type',
      product: 'Product',
      chemicalWeight: 'Chemical weight',
      ligatureWeight: 'Ligature weight',
      amount: 'Amount',
      fixingDate: 'Fixing date',
      sellPrice: 'Sell price (per unit)',
      sellPriceTotal: 'Sell price total',
      buyPrice: 'Buy price (per unit)',
      buyPriceTotal: 'Buy price total',
      supplierSpec: 'Supplier specification',
      totalСost: 'Total cost',
      unitPrice: 'Unit price',
    },
    placeholder: {
      select: 'Select from the directory',
      chemicalWeight: 'Chemical weight',
      ligatureWeight: 'Ligature weight',
      fixingDate: 'Date',
      price: 'Price',
    },
    messages: {
      createSuccess: 'Trade created successfully',
      createError: 'Failed to create trade',
    },
  },
  ru: {
    fields: {
      supplier: 'Поставщик',
      customer: 'Покупатель',
      legalCompanyFrom: 'Юридическое лицо (от кого покупаем)',
      legalCompanyTo: 'Юридическое лицо (от кого продаем)',
      productType: 'Вид товара',
      product: 'Товар',
      chemicalWeight: 'Вес химический',
      ligatureWeight: 'Вес лигатурный',
      amount: 'Сумма сделки покупателя',
      fixingDate: 'Дата фиксинга',
      sellPrice: 'Продажа (за единицу)',
      sellPriceTotal: 'Общая стоимость продажи',
      buyPrice: 'Покупка (за единицу)',
      buyPriceTotal: 'Общая стоимость покупки',
      supplierSpec: 'Документ со спецификацией товаров',
      totalСost: 'Общая стоимость',
      unitPrice: 'Цена за единицу',
    },
    placeholder: {
      select: 'Выберите из справочника',
      chemicalWeight: 'Вес химический',
      ligatureWeight: 'Вес лигатурный',
      fixingDate: 'Дата',
      price: 'Цена',
    },
    messages: {
      createSuccess: 'Сделка успешно создана',
      createError: 'Произошла ошибка при создании сделки',
    },
  },
};
