import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { StepDescription } from '@/shared/ui';
import FormUpload from '@/shared/ui/form/FormUpload';

import { StageModel, StepComponentProps } from '../..';
import { WaitingFieldsWithFilesModel } from '../../model/fieldsModel/WaitingFieldsWithFilesModel';

type Props<StageModel> = StepComponentProps<StageModel>;

const WaitingWithFilesStep = <Model extends StageModel>({ model, fieldKey, description }: Props<Model>) => {
  const fields = fieldKey && model[fieldKey];

  if (!(fields instanceof WaitingFieldsWithFilesModel)) {
    return null;
  }

  const { files } = fields;

  return (
    <StepDescription description={description}>
      {files && files.map(({ docType, file }) => <FormUpload key={docType} model={file} disabled={true} />)}
    </StepDescription>
  );
};

export default observer(WaitingWithFilesStep);
