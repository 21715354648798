import { Select, SelectProps } from 'antd';
import * as React from 'react';

import { TranslationString } from '@/shared/types/localization';
import { ID } from '@/shared/types/meta';
import { Options } from '@/shared/types/values';

import { TransComp } from '../../TransComp';

import { filterOption, filterSort } from './utils';

type CustomSelectProps<Value> = Omit<SelectProps<Value>, 'placeholder' | 'options'> & {
  placeholder?: TranslationString;
  options?: Options<Value>;
};

const CustomSelect = <Value extends ID>({ placeholder = '', options = [], ...props }: CustomSelectProps<Value>) => {
  const optionsList = React.useMemo(() => {
    return options.map((option) => ({
      ...option,
      label: <TransComp>{option.label}</TransComp>,
    }));
  }, [options]);

  return (
    <Select
      {...props}
      placeholder={<TransComp>{placeholder}</TransComp>}
      options={optionsList}
      filterOption={filterOption}
      filterSort={filterSort}
    />
  );
};

export default CustomSelect;
