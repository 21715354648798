import { DictionaryServer, UnitDictionaryServer } from '@/entities/dictionary';
import { StageServer, StageType } from '@/entities/stage';
import { CustomerServer, SupplierServer } from '@/entities/user';
import { ApiListResponse } from '@/shared/types/api';
import { Nullable } from '@/shared/types/values';

export enum TradeStatus {
  creating = 'creating',
  inProgress = 'in_progress',
  finished = 'finished',
}

export type BaseTradeServer = {
  id: number;
  status: TradeStatus;

  customer: Nullable<CustomerServer>;
  supplier: Nullable<SupplierServer>;
  legal_company_from: Nullable<DictionaryServer>;
  legal_company_to: Nullable<DictionaryServer>;
  product_type: DictionaryServer;
  product: Nullable<DictionaryServer>;
  weight: number;
  weight_unit: UnitDictionaryServer;
  fixing_date: string;
  ligature_weight: Nullable<number>;

  buy_price: Nullable<number>;
  buy_price_total: Nullable<number>;
  buy_price_unit: Nullable<UnitDictionaryServer>;
  sell_price: Nullable<number>;
  sell_price_total: Nullable<number>;
  sell_price_unit: Nullable<UnitDictionaryServer>;

  shipped_at: Nullable<string>;
  arrived_in_transit_country_at: Nullable<string>;
  shipped_from_transit_country_at: Nullable<string>;
  arrived_at: Nullable<string>;
  delivered_at: Nullable<string>;
  delivery_days: Nullable<number>;
  transaction_amount_usd: Nullable<number>;
  finished_at: Nullable<string>;
};

export type TradeServer = BaseTradeServer & {
  key: StageType;
};

export type TradeListResponse = ApiListResponse<TradeServer>;

export type TradeInfoServer = BaseTradeServer & {
  tracking_link: Nullable<string>;
  transit_country: Nullable<number>;
  transit_logistic_company: Nullable<DictionaryServer>;
  destination_logistic_company: Nullable<DictionaryServer>;
};

export type TradeWorkflowResponse = TradeInfoServer & {
  id: number;
  stage: StageServer;
};

export type CreateTradeFormData = Partial<{
  file: File;
  customer_id: number;
  supplier_id: number;
  legal_company_from_id: number;
  legal_company_to_id: number;
  product_type_id: number;
  product_id: number;
  weight: number;
  weight_unit_id: number;
  fixing_date: string;
  sell_price: number;
  sell_price_unit_id: number;
  sell_price_total: number;
  buy_price: number;
  buy_price_unit_id: number;
  buy_price_total: number;
}>;
