import {
  ManagerPaymentAction,
  ManagerPaymentStageDataServer,
  ManagerPaymentStep,
  StageType,
} from '@/entities/stage/types';
import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';

import { BaseManagerStageModel, BaseManagerStageModelParams } from '../../BaseManagerStageModel';
import { BaseUploadFileFieldsModel } from '../../fieldsModel/BaseUploadFileFieldsModel';

import { mapManagerStepToNumber } from './config';

type Params = BaseManagerStageModelParams<ManagerPaymentStep> & {
  uploadBillFields: BaseUploadFileFieldsModel<ManagerPaymentStep, ManagerPaymentAction>;
  payBillFields: BaseUploadFileFieldsModel<ManagerPaymentStep, ManagerPaymentAction>;
};

export class ManagerPaymentStageModel extends BaseManagerStageModel<StageType.payment, ManagerPaymentStep> {
  readonly uploadBillFields: BaseUploadFileFieldsModel<ManagerPaymentStep, ManagerPaymentAction>;
  readonly payBillFields: BaseUploadFileFieldsModel<ManagerPaymentStep, ManagerPaymentAction>;

  constructor({ uploadBillFields, payBillFields, ...params }: Params) {
    super({
      type: StageType.payment,
      ...params,
    });

    this.uploadBillFields = uploadBillFields;
    this.payBillFields = payBillFields;
  }

  get currentStep(): number {
    return mapManagerStepToNumber(this.step);
  }

  get steps(): ManagerPaymentStep[] {
    const steps: ManagerPaymentStep[] = [ManagerPaymentStep.uploadBill, ManagerPaymentStep.payBill];

    if (this.step === ManagerPaymentStep.transfer) {
      steps.push(ManagerPaymentStep.transfer);
    }

    return steps;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: {
    dataServer: ManagerPaymentStageDataServer;
    tradeWorkflowStore: ITradeWorkflowStore;
  }): ManagerPaymentStageModel {
    return new ManagerPaymentStageModel({
      uploadBillFields: BaseUploadFileFieldsModel.fromJson({
        data: dataServer.steps[ManagerPaymentStep.uploadBill],
        step: ManagerPaymentStep.uploadBill,
        approveAction: ManagerPaymentStep.uploadBill,
        tradeWorkflowStore,
      }),
      payBillFields: BaseUploadFileFieldsModel.fromJson({
        data: dataServer.steps[ManagerPaymentStep.payBill],
        step: ManagerPaymentStep.payBill,
        approveAction: ManagerPaymentStep.payBill,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
