import { FormItemProps as AntdFormItemProps, Form } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { TranslationString } from '@/shared/types/localization';

import { TransComp } from '../../TransComp';

import { FormItemDescription, FormItemDescriptionProps } from './FormItemDescription';
import { FormItemLabel } from './FormItemLabel';

import s from './FormItem.module.scss';

type BaseProps =
  | {
      model: BaseFieldModel<any>;
    }
  | {
      label: TranslationString;
      required?: boolean;
      tooltip?: TranslationString;
      description?: TranslationString;
    };

type FormItemProps = React.PropsWithChildren<{ className?: string } & BaseProps>;

const FormItem = React.forwardRef<HTMLDivElement, FormItemProps>(({ className, children, ...props }, ref) => {
  const formItemProps: AntdFormItemProps = React.useMemo(() => {
    if ('label' in props) {
      return {
        className: cn(s['form-item'], !props.label && s['form-item_nolabel'], className),
        label: <FormItemLabel {...props} />,
      };
    } else {
      return {
        className: cn(s['form-item'], !props.model.label && s['form-item_nolabel'], className),
        label: (
          <FormItemLabel label={props.model.label} required={props.model.required} tooltip={props.model.tooltip} />
        ),
        validateStatus: props.model.isError ? 'error' : 'success',
        help: <TransComp>{props.model.error}</TransComp>,
      };
    }
  }, [props, className]);

  const descriptionProps: FormItemDescriptionProps = React.useMemo(() => {
    if ('label' in props) {
      return {
        description: props.description,
        required: props.required,
      };
    } else {
      return {
        description: props.model.description,
        required: props.model.required,
      };
    }
  }, [props]);

  return (
    <Form.Item {...formItemProps} colon={false}>
      <FormItemDescription {...descriptionProps} />
      <div ref={ref}>{children}</div>
    </Form.Item>
  );
});

FormItem.displayName = 'FormItem';

export default observer(FormItem);
