import { EditOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import FormInput from '@/shared/ui/form/FormInput';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { AttachingTrackingLinkModel } from '../../model/AttachingTrackingLinkModel';

import s from './AttachingTrackingLink.module.scss';

type AttachingTrackingLinkProps = {
  model: AttachingTrackingLinkModel;
};

const AttachingTrackingLink: React.FC<AttachingTrackingLinkProps> = ({ model }) => {
  const { isInitializedByValue, loadingStage, disabled, isError, changeInitializedByValue, approveTrackingLink } =
    model;

  const handleChangeInitialized = React.useCallback(() => {
    changeInitializedByValue(false);
  }, [changeInitializedByValue]);

  return (
    <Flex vertical gap={8}>
      <FormInput
        className={cn(s['form-input'], !disabled && isInitializedByValue && s['form-input_disabled'])}
        model={model}
        suffix={!disabled && isInitializedByValue && <EditOutlined onClick={handleChangeInitialized} />}
      />
      <ApproveButton
        loading={loadingStage.isLoading}
        disabled={disabled || !model.value || isError || isInitializedByValue}
        onClick={approveTrackingLink}
      />
    </Flex>
  );
};

export default observer(AttachingTrackingLink);
