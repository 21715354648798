import { ApiRequest } from '@kts-front/call-api';
import { BaseResponse } from '@kts-front/types';
import { action, computed, makeObservable } from 'mobx';

import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel } from '@/shared/model';
import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { Nullable } from '@/shared/types/values';

import { DocumentFileType } from '../types';

export type GenerationFieldsModelParams<DocType extends DocumentFileType> = {
  docType: DocType;
  tradeWorkflowStore: ITradeWorkflowStore;
};

export abstract class GenerationFieldsModel<DocType extends DocumentFileType, Payload> {
  protected readonly _fieldsDataRequest: ApiRequest<unknown>;
  protected readonly _tradeWorkflowStore: ITradeWorkflowStore;
  protected readonly _docType: DocType;

  readonly loadingState = new LoadingStageModel();

  constructor({ docType, tradeWorkflowStore }: GenerationFieldsModelParams<DocType>) {
    this._tradeWorkflowStore = tradeWorkflowStore;
    this._docType = docType;

    this._fieldsDataRequest = apiStore.createRequest({
      url: apiUrls.document.generationByDocType(docType),
      params: { trade_id: tradeWorkflowStore.tradeId },
    });

    makeObservable(this, {
      isFilled: computed,
      isError: computed,

      validate: action.bound,
      reset: action.bound,
      toJson: action.bound,
      loadFieldsData: action.bound,
    });
  }

  get isFilled(): boolean {
    for (const field of this.fields) {
      if (field.required && !field.value) {
        return false;
      }
    }

    return true;
  }

  get isError(): boolean {
    return this.fields.some((field) => field.isError);
  }

  validate(): boolean {
    this.fields.forEach((field) => field.validate());

    return this.isError;
  }

  reset(): void {
    this.fields.forEach((field) => field.reset());
  }

  abstract get fields(): Array<BaseFieldModel<any>>;

  abstract toJson(): Nullable<Payload>;

  abstract loadFieldsData(): Promise<BaseResponse>;
}
