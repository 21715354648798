import { BaseResponse } from '@kts-front/types';

import { ListModel } from '@/shared/model';

import { IDictionariesStore, IDictionaryStore } from '../../types/client';
import { DictionaryModel } from '../DictionaryModel';

export class ClientsStore implements IDictionaryStore<DictionaryModel> {
  readonly list = new ListModel<DictionaryModel, number>();

  private _dictionariesStore: IDictionariesStore;

  constructor(dictionariesStore: IDictionariesStore) {
    this._dictionariesStore = dictionariesStore;
  }

  async load(): Promise<BaseResponse> {
    const { loadingClientsStage, loadClients } = this._dictionariesStore;

    if (loadingClientsStage.isLoading) {
      return { isError: false };
    }

    loadingClientsStage.loading();

    const response = await loadClients();

    if (response.isError) {
      loadingClientsStage.error();
    } else {
      loadingClientsStage.success();
    }

    return response;
  }
}
