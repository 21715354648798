import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';

export type WaitingFieldsModelParams<Step> = {
  step: Step;
  tradeWorkflowStore: ITradeWorkflowStore;
};

export class WaitingFieldsModel<Step> {
  readonly step: Step;
  readonly tradeWorkflowStore: ITradeWorkflowStore;

  constructor({ step, tradeWorkflowStore }: WaitingFieldsModelParams<Step>) {
    this.step = step;
    this.tradeWorkflowStore = tradeWorkflowStore;
  }

  static fromJson<Step>(params: WaitingFieldsModelParams<Step>) {
    return new WaitingFieldsModel(params);
  }
}
