export const profile = {
  en: {
    title: 'Profile',
    menu: {
      me: 'My profile',
      company: 'Company',
      notifications: 'Notifications',
    },
    passwordFields: {
      title: 'Change password',
      currentPassword: 'Current password',
      newPassword: 'New password',
      repeatPassword: 'Repeat password',
    },
    errors: {
      invalidCurrentPassword: 'Invalid current password',
    },
    messages: {
      errorUnknown: 'An unknown error has occurred',
      editProfileSuccess: 'Profile updated successfully',
      editProfileError: 'An error occurred while updating the profile',
      editCompanySuccess: 'Company details successfully updated',
      editCompanyError: 'An error occurred while updating the company',
      passwordUpdated: 'Password successfully updated',
      errorUpdatingPassword: 'There was an error updating your password.',
    },
    button: {
      buttonSave: 'Save changes',
      buttonReset: 'Cancel changes',
      buttonChange: 'Change password',
    },
  },
  ru: {
    title: 'Профиль',
    menu: {
      me: 'Мой профиль',
      company: 'Компания',
      notifications: 'Уведомления',
    },
    passwordFields: {
      title: 'Изменение пароля',
      currentPassword: 'Текущий пароль',
      newPassword: 'Новый пароль',
      repeatPassword: 'Повторите пароль',
    },
    errors: {
      invalidCurrentPassword: 'Неверный текущий пароль',
    },
    messages: {
      errorUnknown: 'Произошла неизвестная ошибка',
      editProfileSuccess: 'Профиль успешно обновлен',
      editProfileError: 'Произошла ошибка при обновлении профиля',
      editCompanySuccess: 'Данные о компании успешно обновлены',
      editCompanyError: 'Произошла ошибка при обновлении компании',
      passwordUpdated: 'Пароль успешно обновлен',
      errorUpdatingPassword: 'Произошла ошибка при обновлении пароля',
    },
    button: {
      buttonSave: 'Сохранить изменения',
      buttonReset: 'Отменить изменения',
      buttonChange: 'Изменить пароль',
    },
  },
};
