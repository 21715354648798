import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';

import { BaseFieldsModel } from '../BaseFieldsModel';

enum ManagerTransferFieldsAction {
  transfer = 'transfer',
}

export class ManagerTransferFieldsModel extends BaseFieldsModel<
  ManagerTransferFieldsAction,
  ManagerTransferFieldsAction
> {
  constructor(tradeWorkflowStore: ITradeWorkflowStore) {
    super({
      step: ManagerTransferFieldsAction.transfer,
      approveAction: ManagerTransferFieldsAction.transfer,
      tradeWorkflowStore,
    });
  }

  static fromJson(tradeWorkflowStore: ITradeWorkflowStore): ManagerTransferFieldsModel {
    return new ManagerTransferFieldsModel(tradeWorkflowStore);
  }
}
