import { action, computed, makeObservable, observable } from 'mobx';

import { Nullable } from '@/shared/types/values';

import { LoadingStageModel } from './LoadingStageModel';
import { ValueModel } from './ValueModel';

const ITEMS_ON_PAGE = 10;

export interface IListParams<T, K extends PropertyKey> {
  keys: K[];
  entities: Map<K, T>;
  limit?: number;
}

const defaultParams = {
  keys: [],
  entities: new Map(),
};

export class ListModel<T, K extends PropertyKey = string> implements IListParams<T, K> {
  protected _keys: K[];
  protected _entities: Map<K, T>;
  protected _limit: number;
  protected _initial = true;

  readonly loadingStage: LoadingStageModel = new LoadingStageModel();
  readonly total: ValueModel<number> = new ValueModel<number>(0);

  constructor({ keys, entities, limit }: IListParams<T, K> = defaultParams) {
    this._keys = keys;
    this._entities = entities;
    this._limit = limit ?? ITEMS_ON_PAGE;

    type PrivateFields = '_keys' | '_entities' | '_limit' | '_initial';

    makeObservable<ListModel<T, K>, PrivateFields>(this, {
      _keys: observable,
      _entities: observable,
      _limit: observable,
      _initial: observable,

      keys: computed,
      entities: computed,
      length: computed,
      items: computed,
      limit: computed,
      initial: computed,
      hasMore: computed,

      reset: action,
      removeEntity: action,
      addEntity: action,
      addEntities: action,
      changeLimit: action,
      changeInitial: action,
    });
  }

  get keys(): K[] {
    return this._keys;
  }

  get entities(): Map<K, T> {
    return this._entities;
  }

  get length(): number {
    return this.items.length;
  }

  get items(): T[] {
    const arr: T[] = [];

    this._keys.forEach((id: K) => {
      const item = this._entities.get(id);

      if (item) {
        arr.push(item);
      }
    });

    return arr;
  }

  get limit(): number {
    return this._limit;
  }

  get initial(): boolean {
    return this._initial;
  }

  get hasMore(): boolean {
    return this.total.value > this._keys.length;
  }

  changeLimit = (limit: number): void => {
    this._limit = limit;
  };

  changeInitial = (initial: boolean): void => {
    this._initial = initial;
  };

  addEntity = ({ entity, key, start = false }: { entity: T; key: K; start?: boolean }): void => {
    this._entities.set(key, entity);

    if (start) {
      this._keys.unshift(key);
    } else {
      this._keys.push(key);
    }
  };

  addEntities = ({
    entities,
    keys,
    initial,
    start,
  }: {
    entities: Map<K, T>;
    keys: K[];
    initial: boolean;
    start?: boolean;
  }): void => {
    if (initial) {
      this._entities = entities;
      this._keys = keys;

      return;
    }

    keys.forEach((key) => {
      const entity = entities.get(key);

      if (!entity) {
        return;
      }

      this._entities.set(key, entity);
    });

    if (start) {
      this._keys.unshift(...keys);
    } else {
      this._keys.push(...keys);
    }
  };

  getEntity = (keyParam: K): Nullable<T> => {
    return this._entities.get(keyParam) || null;
  };

  getEntityByIndex = (index: number): Nullable<T> => {
    const key = this._keys[index];

    if (key === undefined) {
      return null;
    }

    return this.getEntity(key);
  };

  removeEntity = (keyParam: K): void => {
    this._keys = this._keys.filter((key) => key !== keyParam);
    this._entities.delete(keyParam);
  };

  reset(): void {
    this._keys = [];
    this._entities = new Map();
    this._initial = true;
    this.total.change(0);
  }

  fillByRawData<S extends Record<string, any>>(
    raw: S[],
    normalizer: (raw: S) => { entity: T; key: K },
    initial = false,
    start = false,
  ): void {
    const keys: K[] = [];
    const entities: Map<K, T> = new Map();

    raw.forEach((item) => {
      const { entity, key } = normalizer(item);

      keys.push(key);
      entities.set(key, entity);
    });

    this.addEntities({
      entities,
      keys,
      initial,
      start,
    });
  }
}
