import { ListModel } from '@/shared/model';

import { IProductDictionary } from '../types/client';
import { ProductDictionaryServer } from '../types/server';

import { DictionaryModel } from './DictionaryModel';

export class ProductDictionaryModel implements IProductDictionary {
  readonly value: number;
  readonly label: string;
  readonly name: string;
  readonly unitList: ListModel<DictionaryModel<number>, number>;
  readonly productList: ListModel<DictionaryModel<number>, number>;

  constructor({ value, label, name, unitList, productList }: IProductDictionary) {
    this.value = value;
    this.label = label;
    this.name = name;
    this.unitList = unitList;
    this.productList = productList;
  }

  static fromJson(server: ProductDictionaryServer): ProductDictionaryModel {
    const unitList = new ListModel<DictionaryModel<number>, number>();

    unitList.fillByRawData(server.units, (raw) => ({
      entity: DictionaryModel.fromJson(raw),
      key: raw.id,
    }));

    const productList = new ListModel<DictionaryModel<number>, number>();

    if (server.products && server.products.length > 0) {
      productList.fillByRawData(server.products, (raw) => ({
        entity: DictionaryModel.fromJson(raw),
        key: raw.id,
      }));
    }

    return new ProductDictionaryModel({
      value: server.id,
      label: server.name,
      name: server.name,
      unitList,
      productList,
    });
  }
}
