import { ApiRequest } from '@kts-front/call-api';
import { action, makeObservable } from 'mobx';

import { MessageType } from '@/entities/message';
import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel } from '@/shared/model';
import { InputModel, InputModelParams } from '@/shared/model/form/InputModel';
import { Nullable } from '@/shared/types/values';

import { OptionFieldsAction } from '../../types';

type AttachingTrackingLinkParams = InputModelParams<string> & {
  tradeWorkflowStore: ITradeWorkflowStore;
};

type AttachingTrackingLinkPayload = {
  action: OptionFieldsAction.setTrackingLink;
  link: string;
};

export class AttachingTrackingLinkModel extends InputModel<string> {
  private readonly _approveRequest: ApiRequest<unknown>;

  readonly loadingStage = new LoadingStageModel();
  readonly tradeWorkflowStore: ITradeWorkflowStore;

  constructor({ tradeWorkflowStore, ...params }: AttachingTrackingLinkParams) {
    super(params);

    this._approveRequest = apiStore.createRequest({
      method: 'POST',
      multipartFormData: true,
      url: apiUrls.trade.action(tradeWorkflowStore.tradeId),
    });

    this.tradeWorkflowStore = tradeWorkflowStore;

    makeObservable(this, {
      approveTrackingLink: action.bound,
    });
  }

  private _toJson(): Nullable<AttachingTrackingLinkPayload> {
    const value = this.value;

    if (!value) {
      return null;
    }

    return {
      action: OptionFieldsAction.setTrackingLink,
      link: value,
    };
  }

  async approveTrackingLink(): Promise<void> {
    const payload = this._toJson();

    if (this.loadingStage.isLoading || !payload) {
      return;
    }

    this.loadingStage.loading();

    const response = await this._approveRequest.call({
      multipartFormData: true,
      data: payload,
    });

    if (response.isError) {
      this.loadingStage.error();
      this.tradeWorkflowStore.rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('optionalFields.messages.saveLinkError', { ns: 'stage' }),
      });

      return;
    }

    this.tradeWorkflowStore.rootStore.notificationsStore.addNotification({
      type: MessageType.success,
      message: (t) => t('optionalFields.messages.saveLinkSuccess', { ns: 'stage' }),
    });

    this.changeInitializedByValue(true);

    this.loadingStage.success();

    this.tradeWorkflowStore.stageModel.optionalFields.reloadOptionalFields();
  }
}
