import { NotificationCategory } from '@/entities/notification';

export const notifications = {
  en: {
    category: {
      [NotificationCategory.statusChange]: 'Trade status changes',
      [NotificationCategory.docUpload]: 'Document upload updates',
      [NotificationCategory.docVerification]: 'Confirmation of document uploading from managers',
    },
    service: {
      telegram: 'To telegram',
      email: 'To email',
    },
    list: {
      actions: {
        readAll: 'Mark all as read',
        typeFilter: 'Filter by type',
        settings: 'Notification settings',
        rangePickerPlaceholder: 'Select date',
        clearList: 'Clear list',
      },
      tradeLink: 'Trade №{{tradeId}}',
      emptyList: 'While the notification list is empty',
      emptyFilteredList: 'No notifications found matching these filters',
      messages: {
        fetchError: 'Failed to fetch notifications',
        readAllSuccess: 'All notifications marked as read',
        readAllError: 'Failed to mark all notifications as read',
        readOneSuccess: 'Notification marked as read',
        readOneError: 'Failed to mark notification as read',
      },
    },
    settings: {
      backButton: 'Back',
      title: 'Notification settings',
      sectionHeadings: {
        whereToSend: 'Where to send',
        whatToSend: 'What to send',
      },
      unsubscribeModal: {
        title: 'Confirm to turn off notifications',
        description: 'Are you sure you want to turn off all notifications? You may miss important updates',
        okText: 'Turn off all',
      },
      messages: {
        fetchError: 'Failed to fetch notification settings',
        changeError: 'Failed to change notification settings',
        changeSuccess: 'Notification settings changed successfully',
      },
    },
  },
  ru: {
    category: {
      [NotificationCategory.statusChange]: 'Изменения статуса заказов',
      [NotificationCategory.docUpload]: 'Обновления по загрузке документов',
      [NotificationCategory.docVerification]: 'Подтверждения по загрузке документов от менеджеров',
    },
    service: {
      telegram: 'В телеграм',
      email: 'На почту',
    },
    list: {
      actions: {
        readAll: 'Отметить все как прочитанные',
        typeFilter: 'Фильтровать по типу',
        settings: 'Настройки уведомлений',
        rangePickerPlaceholder: 'Выберите дату',
        clearList: 'Очистить список',
      },
      tradeLink: 'Сделка №{{tradeId}}',
      emptyList: 'Пока список уведомлений пуст',
      emptyFilteredList: 'По данным фильтрам уведомления не найдены',
      messages: {
        fetchError: 'Ошибка получения уведомлений',
        readAllSuccess: 'Все уведомления отмечены как прочитанные',
        readAllError: 'Не удалось отметить все уведомления как прочитанные',
        readOneSuccess: 'Уведомление отмечено как прочитанное',
        readOneError: 'Не удалось отметить уведомление как прочитанное',
      },
    },
    settings: {
      backButton: 'Назад',
      title: 'Настройки уведомлений',
      sectionHeadings: {
        whereToSend: 'Куда присылать',
        whatToSend: 'Что присылать',
      },
      unsubscribeModal: {
        title: 'Подтвердите отключение уведомлений',
        description: 'Вы уверены, что хотите отключить все уведомления? Вы можете пропустить важные обновления',
        okText: 'Отключить все',
      },
      messages: {
        fetchError: 'Не удалось получить настройки уведомлений',
        changeError: 'Не удалось изменить настройки уведомлений',
        changeSuccess: 'Настройки уведомлений изменены',
      },
    },
  },
};
