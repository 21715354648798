import { DocumentServer, DocumentWithStatusServer, FileModel } from '@/entities/file';
import { UploadFileModel } from '@/shared/model/form/UploadFileModel';
import { Nullable } from '@/shared/types/values';

import { WaitingFieldsModel } from '../WaitingFieldsModel';
import { WaitingFieldsModelParams } from '../WaitingFieldsModel/WaitingFieldsModel';

export type WaitingFieldsWithFileModelParams<Step> = WaitingFieldsModelParams<Step> & {
  file: UploadFileModel;
};

export class WaitingFieldsWithFileModel<Step> extends WaitingFieldsModel<Step> {
  readonly file: UploadFileModel;

  constructor({ file, ...params }: WaitingFieldsWithFileModelParams<Step>) {
    super(params);

    this.file = file;
  }

  static fromJson<Step>({
    data,
    ...params
  }: WaitingFieldsModelParams<Step> & {
    data: Nullable<DocumentServer | DocumentWithStatusServer>;
  }) {
    return new WaitingFieldsWithFileModel({
      file: new UploadFileModel({
        initialValue: FileModel.fileListFromJson(data?.document),
        maxCount: 1,
      }),
      ...params,
    });
  }
}
