import { MessageInstance } from 'antd/es/message/interface';
import cn from 'classnames';
import { action, makeObservable } from 'mobx';
import { nanoid } from 'nanoid';

import { ILocalizationStore } from '@/app/store/LocalizationStore';
import s from '@/shared/styles/notification.module.scss';
import { renderTranslationString } from '@/shared/utils/renderTranslation';

import { IMessage } from '../types';

import { INotificationsStore, MessageKey } from './types';

type Params = {
  messageApi: MessageInstance;
  localizationStore: ILocalizationStore;
};

export class NotificationsStore implements INotificationsStore {
  private readonly _api: MessageInstance;
  private readonly _localizationStore: ILocalizationStore;

  constructor({ messageApi, localizationStore }: Params) {
    this._api = messageApi;
    this._localizationStore = localizationStore;

    makeObservable(this, {
      addNotification: action.bound,
    });
  }

  addNotification({ type, message, ...args }: IMessage): MessageKey {
    const key = nanoid();

    this._api.open({
      className: cn(s.message, s[`message_${type}`]),
      key,
      content: renderTranslationString(message, this._localizationStore.t),
      type: type,
      ...args,
    });

    return key;
  }

  destroy(key: MessageKey): void {
    this._api.destroy(key);
  }
}
