import { StepProps, Steps, StepsProps } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { ProgressIcon } from '../icons';

import s from './StageContent.module.scss';

type StageStepsProps = {
  className?: string;
  currentStep: number;
  items: StepProps[];
  asSingleStep?: boolean;
};

type ProgressDotRender = NonNullable<Exclude<StepsProps['progressDot'], boolean>>;
const progressDotRender: ProgressDotRender = (dot, { status }) => (status === 'finish' ? <ProgressIcon /> : dot);

const StageSteps: React.FC<StageStepsProps> = ({ className, currentStep, items, asSingleStep }) => {
  const stepsWrapperRef = React.useRef<HTMLDivElement>(null);

  // Автоскролл к активному шагу этапа
  React.useEffect(() => {
    if (stepsWrapperRef.current) {
      const activeSteps = stepsWrapperRef.current.getElementsByClassName('ant-steps-item-active');

      if (activeSteps.length) {
        activeSteps[0].scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }
    }
  }, [currentStep]);

  return (
    <div className={cn(s.stageSteps, asSingleStep && s.stageSteps_singleStep)} ref={stepsWrapperRef}>
      <Steps
        className={className}
        progressDot={progressDotRender}
        direction="vertical"
        current={currentStep}
        items={items}
      />
    </div>
  );
};

export default React.memo(StageSteps);
