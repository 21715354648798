import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { CheckboxListModel } from '@/shared/model/form/CheckboxListModel';
import { ID } from '@/shared/types/meta';

import CustomCheckboxGroup from '../CustomCheckboxGroup';
import { FormItem } from '../FormItem';

type FormCheckboxListProps<Value> = {
  className?: string;
  model: CheckboxListModel<Value>;
  disabled?: boolean;
};

const FormCheckboxList = <Value extends ID>({ className, model, disabled }: FormCheckboxListProps<Value>) => {
  return (
    <FormItem className={className} ref={model.ref} model={model}>
      <CustomCheckboxGroup {...model.props} disabled={disabled} />
    </FormItem>
  );
};

export default observer(FormCheckboxList);
