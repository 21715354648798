import { TFunction, TOptions } from 'i18next';
import * as React from 'react';

import { NameSpace, NameSpaces } from '@/app/locales/types';

export const defaultNameSpace: NameSpace = 'shared';
export type DefaultNameSpace = typeof defaultNameSpace;

export enum SupportedLocale {
  en = 'en',
  ru = 'ru',
}

export const defaultLanguage: SupportedLocale = SupportedLocale.ru;
export const fallbackLanguage: SupportedLocale = SupportedLocale.en;
export const keySeparator = '.';

export type Translation = {
  [key: string]: string | Translation;
};

export type NameSpaceTranslation = {
  [locale in SupportedLocale]: Translation;
};

export type Translations = {
  [nameSpace in NameSpace]: Translation;
};

type AllLoadedNameSpaceType<NS extends NameSpace> = NameSpaces[NS];
type AllLoadedNameSpaceTypeByLanguage<NS extends NameSpace> = AllLoadedNameSpaceType<NS>[typeof defaultLanguage];

type RecursiveKeyOf<TObj extends Record<string, unknown>> = {
  [TKey in keyof TObj & (string | number)]: TObj[TKey] extends unknown[]
    ? `${TKey}`
    : TObj[TKey] extends Record<string, unknown>
      ? `${TKey}${typeof keySeparator}${RecursiveKeyOf<TObj[TKey]>}`
      : `${TKey extends `${infer B}_${'zero' | 'one' | 'two' | 'few' | 'many' | 'other'}` ? B : TKey}`;
}[keyof TObj & (string | number)];

type FlattenTypedKey<NS extends NameSpace> = AllLoadedNameSpaceTypeByLanguage<NS>;

export type TranslationKey<NS extends NameSpace> = RecursiveKeyOf<FlattenTypedKey<NS>>;

export type TypedNameSpaceOptions<NS extends NameSpace> = Omit<TOptions, 'ns'> & { ns?: NS };

export type TypedTranslationOptions<NS extends NameSpace> = string | TypedNameSpaceOptions<NS> | undefined;

export type TFunctionParams<NS extends NameSpace> = Parameters<TFunction<NS, undefined>>;

export type TFunctionType<NE extends NameSpace | undefined = undefined> = <
  NS extends NameSpace | undefined = undefined,
>(
  key: NS extends NameSpace
    ? TranslationKey<NS>
    : NE extends NameSpace
      ? TranslationKey<NE>
      : TranslationKey<DefaultNameSpace>,
  options?: NS extends NameSpace
    ? TypedTranslationOptions<NS>
    : NE extends NameSpace
      ? TypedTranslationOptions<NE>
      : TypedTranslationOptions<DefaultNameSpace>,
  defaultValue?: NS extends NameSpace
    ? TFunctionParams<NS>
    : (NE extends NameSpace ? TFunctionParams<NE> : TFunctionParams<DefaultNameSpace>)[1],
) => string;

export type TypedTranslationResponse<NE extends NameSpace | undefined = undefined> = {
  t: TFunctionType<NE>;
  lng: SupportedLocale;
};

export type TranslationString = string | ((t: TFunctionType) => string);
export type TranslationNode = TranslationString | React.ReactNode;
