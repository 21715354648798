import { ApiRequest } from '@kts-front/call-api';
import { action, computed, makeObservable } from 'mobx';

import { DictionaryField } from '@/entities/dictionary';
import { MessageType } from '@/entities/message';
import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel } from '@/shared/model';
import { SelectModel, SelectModelParams } from '@/shared/model/form/SelectModel';
import { Nullable, Options } from '@/shared/types/values';

import { OptionFieldsAction } from '../../types';

type SelectOptionalOptionAction =
  | OptionFieldsAction.setDestinationLogisticCompany
  | OptionFieldsAction.setTransitLogisticCompany;

type ModelParams<Value> = SelectModelParams<Value> & {
  tradeWorkflowStore: ITradeWorkflowStore;
  approveAction: SelectOptionalOptionAction;
  dictionaryFieldName: DictionaryField;
};

type SelectOptionalOptionPayload<Value> = {
  action: SelectOptionalOptionAction;
  id: Value;
};

export class SelectOptionalOptionModel<Value = number> extends SelectModel<Value> {
  private readonly _dictionaryFieldName: DictionaryField;
  private readonly _selectRequest: ApiRequest<unknown>;
  private readonly _approveAction: SelectOptionalOptionAction;

  readonly loadingStage = new LoadingStageModel();
  readonly tradeWorkflowStore: ITradeWorkflowStore;

  constructor({ dictionaryFieldName, approveAction, tradeWorkflowStore, ...params }: ModelParams<Nullable<Value>>) {
    super(params);

    this._selectRequest = apiStore.createRequest({
      method: 'POST',
      multipartFormData: true,
      url: apiUrls.trade.action(tradeWorkflowStore.tradeId),
    });

    this._dictionaryFieldName = dictionaryFieldName;
    this._approveAction = approveAction;
    this.tradeWorkflowStore = tradeWorkflowStore;

    makeObservable(this, {
      options: computed,
      selectOption: action.bound,
    });
  }

  get options(): Options<Value> {
    return this.tradeWorkflowStore.dictionaries[this._dictionaryFieldName].list.items as Options<Value>;
  }

  private _toJson(): Nullable<SelectOptionalOptionPayload<Value>> {
    const value = this.value;

    if (!value) {
      return null;
    }

    return {
      action: this._approveAction,
      id: value,
    };
  }

  async selectOption(): Promise<void> {
    const payload = this._toJson();

    if (this.loadingStage.isLoading || !payload) {
      return;
    }

    this.loadingStage.loading();

    const response = await this._selectRequest.call({
      multipartFormData: true,
      data: payload,
    });

    if (response.isError) {
      this.loadingStage.error();
      this.tradeWorkflowStore.rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('optionalFields.messages.saveDataError', { ns: 'stage' }),
      });

      return;
    }

    this.tradeWorkflowStore.rootStore.notificationsStore.addNotification({
      type: MessageType.success,
      message: (t) => t('optionalFields.messages.saveDataSuccess', { ns: 'stage' }),
    });

    this.loadingStage.success();

    this.tradeWorkflowStore.stageModel.optionalFields.reloadOptionalFields();
  }
}
