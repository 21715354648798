import { Button, Empty } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks/useTranslation';

import s from './EmptySearchStub.module.scss';

type EmptySearchStubProps = {
  onResetClick: () => void;
  className?: string;
};

const EmptySearchStub = ({
  onResetClick,
  className,
}: EmptySearchStubProps): React.ReactElement<EmptySearchStubProps> => {
  const { t } = useTypedTranslation('shared');

  return (
    <Empty className={cn(s['empty-search-stub'], className)} description={t('components.EmptySearchStub.description')}>
      <Button type="primary" onClick={onResetClick}>
        {t('components.EmptySearchStub.button')}
      </Button>
    </Empty>
  );
};

export default EmptySearchStub;
