export enum ApiErrorCode {
  authenticationFailed = 'authentication_failed',
  tokenNotValid = 'token_not_valid',
  emailAlreadyExists = 'email_already_exists',
  invalidCurrentPassword = 'invalid_current_password_error',
}

export type ApiErrorData<Data extends Record<string, unknown> = Record<string, unknown>> = {
  status: string;
  code: ApiErrorCode;
  message: string;
  data?: Data;
};

export type ApiFieldsErrorData<Fields extends string> = ApiErrorData & {
  field_problems?: Partial<Record<Fields, string[]>>;
};

export type ApiListResponse<R> = {
  total: number;
  results: R[];
};
