import { action, computed, makeObservable } from 'mobx';
import { createRef } from 'react';

import { IRootStore } from '@/app/store';
import { UserType, mapUserTypeToLabel } from '@/entities/user';
import { CheckboxListModel } from '@/shared/model/form/CheckboxListModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { InputTextAreaModel } from '@/shared/model/form/InputTextAreaModel';
import { emptyValueValidator } from '@/shared/utils/validators';

import { DocumentModel } from './DocumentModel';

const T_OPTIONS = { ns: 'file' } as const;

export class ArbitraryDocOptionsModel {
  readonly docOptionsRef = createRef<HTMLDivElement>();

  readonly docCustomType = new InputModel({
    initialValue: '',
    label: (t) => t('arbitraryDocForm.docCustomType.label', T_OPTIONS),
    placeholder: (t) => t('arbitraryDocForm.docCustomType.placeholder', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator()],
  });

  readonly docComment = new InputTextAreaModel({
    initialValue: '',
    label: (t) => t('arbitraryDocForm.docComment.label', T_OPTIONS),
    placeholder: (t) => t('arbitraryDocForm.docComment.placeholder', T_OPTIONS),
    required: false,
  });

  readonly docVisibility = new CheckboxListModel({
    initialValue: [],
    label: (t) => t('arbitraryDocForm.docVisibility.label', T_OPTIONS),
    options: [
      { label: mapUserTypeToLabel[UserType.supplier], value: UserType.supplier },
      { label: mapUserTypeToLabel[UserType.customer], value: UserType.customer },
    ],
    required: true,
    validators: [emptyValueValidator()],
  });

  constructor({ docModel, rootStore }: { docModel?: DocumentModel; rootStore: IRootStore }) {
    const isSupplier = rootStore.userStore.isSupplierRole;
    const isCustomer = rootStore.userStore.isCustomerRole;

    if (docModel) {
      this.docCustomType.change(docModel.docType);
      this.docComment.change(docModel.comment ?? '');
      this.docVisibility.change(docModel.roles ?? []);
    }

    if (isSupplier) {
      this.docVisibility.change([UserType.supplier]);
    }

    if (isCustomer) {
      this.docVisibility.change([UserType.customer]);
    }

    makeObservable(this, {
      isError: computed,
      validate: action.bound,
      scrollToOptions: action.bound,
    });
  }

  get isError(): boolean {
    return this.docCustomType.isError || this.docVisibility.isError;
  }

  validate(): void {
    this.docCustomType.validate();
    this.docComment.validate();
    this.docVisibility.validate();
  }

  scrollToOptions(): void {
    setTimeout(() => {
      if (this.docOptionsRef.current) {
        this.docOptionsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 0);
  }
}
