import { action, autorun, computed, makeObservable, reaction } from 'mobx';

import { IRootStore } from '@/app/store';
import { IDictionariesStore, IDictionary, ProductDictionaryModel } from '@/entities/dictionary';
import { DocumentFileType, FileModel } from '@/entities/file';
import { MessageType } from '@/entities/message';
import {
  ICreationStage,
  ManagerCreationStageDataServer,
  ManagerCreationStep,
  OptionFieldsAction,
} from '@/entities/stage';
import { CreateTradeFormData, TradeWorkflowResponse } from '@/entities/trade';
import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, LocalStore, ToggleModel } from '@/shared/model';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { UploadFileModel } from '@/shared/model/form/UploadFileModel';
import { stringIsNumber } from '@/shared/types/typesGuard';
import { Nullable } from '@/shared/types/values';
import { numberParser } from '@/shared/utils';
import { formatDate } from '@/shared/utils/formatDate';
import { FieldError, emptyValueValidator } from '@/shared/utils/validators';

import { BaseManagerStageModel } from '../../../BaseManagerStageModel';

type Params = {
  data: ICreationStage;
  rootStore: IRootStore;
  tradeWorkflowStore?: Nullable<ITradeWorkflowStore>;
};

const T_OPTONS = { ns: 'createTrade' } as const;

export class CreateTradeFieldsModel extends LocalStore {
  readonly supplier = new SelectModel<number>({
    initialValue: null,
    label: (t) => t('fields.supplier', T_OPTONS),
    placeholder: (t) => t('placeholder.select', T_OPTONS),
    required: true,
    validators: [emptyValueValidator()],
    ignoreOnBlurValidation: true,
  });
  readonly customer = new SelectModel<number>({
    initialValue: null,
    label: (t) => t('fields.customer', T_OPTONS),
    placeholder: (t) => t('placeholder.select', T_OPTONS),
    required: true,
    validators: [emptyValueValidator()],
    ignoreOnBlurValidation: true,
  });
  readonly legalCompanyFrom = new SelectModel<number>({
    initialValue: null,
    label: (t) => t('fields.legalCompanyFrom', T_OPTONS),
    placeholder: (t) => t('placeholder.select', T_OPTONS),
    required: true,
    validators: [emptyValueValidator()],
    ignoreOnBlurValidation: true,
  });
  readonly legalCompanyTo = new SelectModel<number>({
    initialValue: null,
    label: (t) => t('fields.legalCompanyTo', T_OPTONS),
    placeholder: (t) => t('placeholder.select', T_OPTONS),
    validators: [emptyValueValidator()],
    ignoreOnBlurValidation: true,
    required: true,
  });
  readonly productType = new SelectModel<number>({
    initialValue: null,
    label: (t) => t('fields.productType', T_OPTONS),
    placeholder: (t) => t('placeholder.select', T_OPTONS),
    validators: [emptyValueValidator()],
    required: true,
  });
  readonly product = new SelectModel<number>({
    initialValue: null,
    placeholder: (t) => t('placeholder.select', T_OPTONS),
    validators: [emptyValueValidator()],
    required: true,
  });
  readonly weight = new InputModel({
    initialValue: '',
    label: (t) => t('fields.chemicalWeight', T_OPTONS),
    placeholder: (t) => t('placeholder.chemicalWeight', T_OPTONS),
    parser: numberParser,
    validators: [emptyValueValidator()],
    required: true,
  });

  readonly ligatureWeight = new InputModel({
    initialValue: '',
    label: (t) => t('fields.ligatureWeight', T_OPTONS),
    placeholder: (t) => t('placeholder.ligatureWeight', T_OPTONS),
    parser: numberParser,
    validators: [emptyValueValidator()],
    required: true,
  });

  readonly weightUnit = new SelectModel<number>({
    initialValue: null,
    validators: [emptyValueValidator(FieldError.weightUnit)],
    ignoreOnBlurValidation: true,
    required: true,
  });
  readonly fixingDate = new DatePickerModel({
    initialValue: null,
    label: (t) => t('fields.fixingDate', T_OPTONS),
    placeholder: (t) => t('placeholder.fixingDate', T_OPTONS),
    validators: [emptyValueValidator()],
    required: true,
  });
  readonly sellPrice = new InputModel({
    initialValue: '',
    label: (t) => t('fields.sellPrice', T_OPTONS),
    placeholder: (t) => t('placeholder.price', T_OPTONS),
    parser: numberParser,
    validators: [emptyValueValidator()],
    ignoreOnBlurValidation: true,
    required: true,
  });
  readonly sellPriceUnit = new SelectModel<number>({
    initialValue: null,
    validators: [emptyValueValidator(FieldError.sellPriceUnit)],
    ignoreOnBlurValidation: true,
    required: true,
  });
  readonly sellPriceTotal = new InputModel({
    initialValue: '',
    label: (t) => t('fields.sellPriceTotal', T_OPTONS),
    placeholder: (t) => t('placeholder.price', T_OPTONS),
    parser: numberParser,
    validators: [emptyValueValidator()],
    ignoreOnBlurValidation: true,
    required: true,
  });
  readonly buyPrice = new InputModel({
    initialValue: '',
    label: (t) => t('fields.buyPrice', T_OPTONS),
    placeholder: (t) => t('placeholder.price', T_OPTONS),
    parser: numberParser,
    validators: [emptyValueValidator()],
    ignoreOnBlurValidation: true,
    required: true,
  });
  readonly buyPriceUnit = new SelectModel<number>({
    initialValue: null,
    validators: [emptyValueValidator(FieldError.buyPriceUnit)],
    ignoreOnBlurValidation: true,
    required: true,
  });
  readonly buyPriceTotal = new InputModel({
    initialValue: '',
    label: (t) => t('fields.buyPriceTotal', T_OPTONS),
    placeholder: (t) => t('placeholder.price', T_OPTONS),
    parser: numberParser,
    validators: [emptyValueValidator()],
    ignoreOnBlurValidation: true,
    required: true,
  });
  readonly supplierSpec = new UploadFileModel({
    initialValue: [],
    label: (t) => t('fields.supplierSpec', T_OPTONS),
  });

  readonly rootStore: IRootStore;

  readonly requestStage = new LoadingStageModel();
  readonly deleteSupplierSpecStage = new LoadingStageModel();
  readonly deleteSupplierSpecModalState = new ToggleModel();

  readonly tradeWorkflowStore: Nullable<ITradeWorkflowStore> = null;

  private readonly _tradeRequest = apiStore.createRequest<TradeWorkflowResponse>({
    method: 'POST',
  });

  private readonly _updateFieldsByInitData = (data: ICreationStage) => {
    const {
      customer,
      supplier,
      legalCompanyFrom,
      legalCompanyTo,
      productType,
      product,
      weight,
      weightUnit,
      fixingDate,
      sellPrice,
      sellPriceUnit,
      sellPriceTotal,
      buyPrice,
      buyPriceUnit,
      buyPriceTotal,
      supplierSpec,
      ligatureWeight,
    } = data;

    this.customer.change(customer);
    this.supplier.change(supplier);
    this.legalCompanyFrom.change(legalCompanyFrom);
    this.legalCompanyTo.change(legalCompanyTo);
    this.productType.change(productType);
    this.product.change(product?.id ?? null);
    this.product.changeLabel(product?.label ?? '');
    this.weight.change(weight);
    this.weightUnit.change(weightUnit);
    this.fixingDate.change(fixingDate);
    this.sellPrice.change(sellPrice);
    this.sellPriceUnit.change(sellPriceUnit);
    this.sellPriceTotal.change(sellPriceTotal);
    this.buyPrice.change(buyPrice);
    this.buyPriceUnit.change(buyPriceUnit);
    this.buyPriceTotal.change(buyPriceTotal);

    this.supplierSpec.change(supplierSpec);
    this.supplierSpec.changeInitializedByValue(supplierSpec.length > 0);
    this.ligatureWeight.change(ligatureWeight);
  };

  protected readonly formFields: (keyof ICreationStage)[] = [
    'supplier',
    'customer',
    'legalCompanyFrom',
    'legalCompanyTo',
    'productType',
    'product',
    'weight',
    'weightUnit',
    'fixingDate',
    'buyPrice',
    'buyPriceUnit',
    'sellPrice',
    'sellPriceUnit',
    'buyPriceTotal',
    'sellPriceTotal',
    'supplierSpec',
    'ligatureWeight',
  ];

  protected readonly scrollToErrorField = () => {
    const errorField = this.formFields.find((field) => this[field].isError);

    if (errorField) {
      this[errorField].scrollToField();
    }
  };

  protected toJson(): CreateTradeFormData {
    const files = this.supplierSpec.value;
    const file = files.length > 0 && this.supplierSpec.value[0].originFileObj;

    return {
      ...(file ? { file } : {}),
      ...(this.customer.value ? { customer_id: this.customer.value } : {}),
      ...(this.supplier.value ? { supplier_id: this.supplier.value } : {}),
      ...(this.legalCompanyFrom.value ? { legal_company_from_id: this.legalCompanyFrom.value } : {}),
      ...(this.legalCompanyTo.value ? { legal_company_to_id: this.legalCompanyTo.value } : {}),
      ...(this.productType.value ? { product_type_id: this.productType.value } : {}),
      ...(this.product.value ? { product_id: this.product.value } : {}),
      ...(this.weight.value && stringIsNumber(this.weight.value) ? { weight: Number(this.weight.value) } : {}),
      ...(this.weightUnit.value ? { weight_unit_id: this.weightUnit.value } : {}),
      ...(this.fixingDate.value ? { fixing_date: formatDate(this.fixingDate.value) } : {}),
      ...(this.sellPriceUnit.value ? { sell_price_unit_id: this.sellPriceUnit.value } : {}),
      ...(this.buyPriceUnit.value ? { buy_price_unit_id: this.buyPriceUnit.value } : {}),
      ...(this.sellPrice.value && stringIsNumber(this.sellPrice.value)
        ? { sell_price: Number(this.sellPrice.value) }
        : {}),
      ...(this.buyPrice.value && stringIsNumber(this.buyPrice.value) ? { buy_price: Number(this.buyPrice.value) } : {}),
      ...(this.sellPriceTotal.value && stringIsNumber(this.sellPriceTotal.value)
        ? { sell_price_total: Number(this.sellPriceTotal.value) }
        : {}),
      ...(this.buyPriceTotal.value && stringIsNumber(this.buyPriceTotal.value)
        ? { buy_price_total: Number(this.buyPriceTotal.value) }
        : {}),
      ...(this.ligatureWeight.value && stringIsNumber(this.ligatureWeight.value)
        ? { ligature_weight: Number(this.ligatureWeight.value) }
        : {}),
    };
  }

  constructor({ data, rootStore, tradeWorkflowStore = null }: Params) {
    super();

    this.rootStore = rootStore;
    this.tradeWorkflowStore = tradeWorkflowStore;

    this._updateFieldsByInitData(data);

    makeObservable(this, {
      isError: computed,
      formDisabled: computed,
      weightUnitOptions: computed,
      productTypeHasProducts: computed,
      productOptions: computed,

      editTrade: action.bound,
      deleteSupplierSpec: action.bound,
    });

    this.addReactions([
      // Если значений для Валют не было при инициализации,
      // то устанавливаем значения, после получения словарей
      autorun(() => {
        if (this.dictionaries.loadingStage.isSuccess) {
          const currencyList = this.dictionaries.currencies.list.items;
          const defaultCurrency = currencyList.find((currency) => currency.label === 'RUB') ?? currencyList[0];

          !this.buyPriceUnit.value && this.buyPriceUnit.change(defaultCurrency.value);
          !this.sellPriceUnit.value && this.sellPriceUnit.change(defaultCurrency.value);
        }
      }),
      // Проверяем ошибку "Валюты покупки" и выставляем ее для "Цены покупки",
      // чтобы отобразить в форме, даже при отсутсвии ошибки в "Цене покупки"
      reaction(
        () => ({ priceValue: this.buyPrice.value, unitError: this.buyPriceUnit.error }),
        ({ unitError }) => (unitError ? this.buyPrice.changeError(unitError) : this.buyPrice.resetError()),
      ),
      // Проверяем ошибку "Валюты продажи" и выставляем ее для "Цены продажи",
      // чтобы отобразить в форме, даже при отсутсвии ошибки в "Цене продажи"
      reaction(
        () => ({ priceValue: this.sellPrice.value, unitError: this.sellPriceUnit.error }),
        ({ unitError }) => (unitError ? this.sellPrice.changeError(unitError) : this.sellPrice.resetError()),
      ),
      // При изменении "Вид товара" сбрасываем "Единицу измерения" и выставляем ее по умолчанию,
      // также сбрасываем product и меняем его label
      reaction(
        () => this.productType.value,
        () => {
          const defaultUnit = this.weightUnitOptions.length ? this.weightUnitOptions[0].value : null;
          this.weightUnit.change(defaultUnit);
          this.product.reset();
          this.ligatureWeight.reset();
          this.product.changeLabel(this.productTypeModel?.label ?? '');
        },
      ),
    ]);
  }

  private get productTypeModel(): Nullable<ProductDictionaryModel> {
    return this.productType.value !== null
      ? this.dictionaries.productTypes.list.getEntity(this.productType.value)
      : null;
  }

  get dictionaries(): IDictionariesStore {
    return this.rootStore.dictionariesStore;
  }

  get isError(): boolean {
    return this.formFields.some((field) => {
      return this[field].isError;
    });
  }

  get formDisabled(): boolean {
    return this.requestStage.isLoading;
  }

  get productTypeHasProducts(): boolean {
    return this.productTypeModel !== null && this.productTypeModel.productList.length > 0;
  }

  get productOptions(): IDictionary[] {
    return this.productTypeModel?.productList.items ?? [];
  }

  get weightUnitOptions(): IDictionary[] {
    let options: IDictionary[] = [];

    if (this.productType.value) {
      options = this.dictionaries.productTypes.list.getEntity(Number(this.productType.value))?.unitList.items ?? [];
    }

    return options;
  }

  async deleteSupplierSpec(): Promise<void> {
    if (this.deleteSupplierSpecStage.isLoading || !this.tradeWorkflowStore) {
      return;
    }

    this.deleteSupplierSpecStage.loading();

    const response = await this._tradeRequest.call({
      url: apiUrls.trade.action(this.tradeWorkflowStore.tradeId),
      multipartFormData: true,
      data: {
        action: OptionFieldsAction.deleteSupplierSpec,
      },
    });

    if (response.isError) {
      this.deleteSupplierSpecStage.error();

      return;
    }

    this.deleteSupplierSpecStage.success();
    this.deleteSupplierSpecModalState.close();

    this.tradeWorkflowStore.updateTradeWorkflow(response.data);
  }

  async editTrade(): Promise<void> {
    if (this.requestStage.isLoading || !this.tradeWorkflowStore) {
      return;
    }

    this.formFields.forEach((field) => {
      if ((field == 'product' || field == 'ligatureWeight') && !this.productTypeHasProducts) {
        return;
      }

      this[field].validate();
    });

    if (this.isError) {
      this.scrollToErrorField();

      return;
    }

    const transferFields =
      this.tradeWorkflowStore.stageModel instanceof BaseManagerStageModel
        ? this.tradeWorkflowStore.stageModel.transferFields
        : null;

    this.requestStage.loading();
    transferFields?.loadingStage.loading();

    const response = await this._tradeRequest.call({
      url: apiUrls.trade.edit(this.tradeWorkflowStore.tradeId),
      multipartFormData: true,
      data: this.toJson(),
    });

    if (response.isError) {
      this.requestStage.error();
      transferFields?.loadingStage.error();
      this.rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.createError', { ns: 'createTrade' }),
      });

      return;
    }

    this.requestStage.success();
    transferFields?.loadingStage.success();
    this.rootStore.notificationsStore.addNotification({
      type: MessageType.success,
      message: (t) => t('messages.createSuccess', { ns: 'createTrade' }),
    });

    this.tradeWorkflowStore.updateTradeWorkflow(response.data);
  }

  destroy(): void {
    super.destroy();
  }

  static fromJson({
    server,
    tradeWorkflowStore,
  }: {
    server: ManagerCreationStageDataServer;
    tradeWorkflowStore: ITradeWorkflowStore;
  }): CreateTradeFieldsModel {
    const info = server.steps[ManagerCreationStep.createTrade];
    const supplierSpecification = server[DocumentFileType.supplierSpecification];

    return new CreateTradeFieldsModel({
      tradeWorkflowStore,
      rootStore: tradeWorkflowStore.rootStore,
      data: {
        supplierSpec: FileModel.fileListFromJson(supplierSpecification),
        customer: info.customer?.id ?? null,
        supplier: info.supplier?.id ?? null,
        legalCompanyFrom: info.legal_company_from?.id ?? null,
        legalCompanyTo: info.legal_company_to?.id ?? null,
        productType: info.product_type.id,
        product: info.product?.id
          ? {
              id: info.product.id,
              label: info.product_type.name,
            }
          : null,
        weight: String(info.weight),
        weightUnit: info.weight_unit.id,
        fixingDate: new Date(info.fixing_date),
        sellPrice: info.sell_price ? String(info.sell_price) : '',
        sellPriceUnit: info.sell_price_unit?.id ?? null,
        buyPrice: info.buy_price ? String(info.buy_price) : '',
        buyPriceUnit: info.buy_price_unit?.id ?? null,
        sellPriceTotal: info.sell_price_total ? String(info.sell_price_total) : '',
        buyPriceTotal: info.buy_price_total ? String(info.buy_price_total) : '',
        ligatureWeight: info.ligature_weight ? String(info.ligature_weight) : '',
      },
    });
  }
}
