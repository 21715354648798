import { Nullable } from './values';

export enum LoadingStage {
  notStarted = 'notStarted',
  loading = 'loading',
  success = 'success',
  error = 'error',
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
  none = 'none',
}

export type SortDirectionAntd = 'ascend' | 'descend' | undefined | null;

export type ID = number | string;

export type MapDataFieldsToNullable<T> = {
  [K in keyof T]: Nullable<T[K]>;
};

export type MapDataFieldsToNull<T> = {
  [K in keyof T]: null;
};
