import { Typography } from 'antd';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks/useTranslation';

export type FormItemOptionalProps = {
  required?: boolean;
};

export const FormItemOptional: React.FC<FormItemOptionalProps> = ({ required }) => {
  const { t } = useTypedTranslation('shared');

  if (required) return null;

  return <Typography.Text type="secondary">({t('components.FormItem.optional')})</Typography.Text>;
};
