import { BaseResponse } from '@kts-front/types';

import { apiStore, apiUrls } from '@/shared/api';
import { ListModel } from '@/shared/model';
import { ApiListResponse } from '@/shared/types/api';
import { ID } from '@/shared/types/meta';

import { IDictionaryStore } from '../../types/client';
import { DictionaryServer, UnitDictionaryListResponse } from '../../types/server';
import { DictionaryModel } from '../DictionaryModel';

import { defaultParams } from './config';

export type DictionaryListResponse<T extends ID = number> = ApiListResponse<DictionaryServer<T>>;

export class CountriesStore implements IDictionaryStore<DictionaryModel> {
  readonly list = new ListModel<DictionaryModel, number>();

  private readonly countryListRequest = apiStore.createRequest<UnitDictionaryListResponse>({
    url: apiUrls.dictionaries.countryList,
  });

  async load(): Promise<BaseResponse> {
    const response = await this.countryListRequest.call({
      params: defaultParams,
    });

    if (response.isError) {
      return { isError: true };
    }

    this.list.fillByRawData(
      response.data.results,
      (raw) => ({
        entity: DictionaryModel.fromJson({
          id: raw.id,
          name: raw.name,
        }),
        key: raw.id,
      }),
      true,
    );

    return { isError: false };
  }
}
