import { DocumentServer } from '@/entities/file';
import { UserType } from '@/entities/user';
import { Nullable } from '@/shared/types/values';

import { BaseStageDataServer } from './baseStage';

export enum ManagerPaymentStep {
  uploadBill = 'upload_bill',
  payBill = 'pay_bill',
  transfer = 'transfer',
}

export type ManagerPaymentAction = ManagerPaymentStep;

export enum SupplierPaymentStep {
  uploadBill = 'upload_bill',
  payBill = 'pay_bill',
  transfer = 'transfer',
}

export type SupplierPaymentAction = SupplierPaymentStep;

export type MapUserTypeToPaymentStageData = {
  [UserType.admin]: {
    [ManagerPaymentStep.uploadBill]: Nullable<DocumentServer>;
    [ManagerPaymentStep.payBill]: Nullable<DocumentServer>;
  };
  [UserType.manager]: {
    [ManagerPaymentStep.uploadBill]: Nullable<DocumentServer>;
    [ManagerPaymentStep.payBill]: Nullable<DocumentServer>;
  };
  [UserType.supplier]: {
    [SupplierPaymentStep.uploadBill]: Nullable<DocumentServer>;
    [SupplierPaymentStep.payBill]: Nullable<DocumentServer>;
  };
  [UserType.customer]: null;
  [UserType.agent]: null;
};

type BasePaymentStageDataServer<U extends UserType, Step> = BaseStageDataServer<Step, MapUserTypeToPaymentStageData[U]>;

export type ManagerPaymentStageDataServer = BasePaymentStageDataServer<
  UserType.manager | UserType.admin,
  ManagerPaymentStep
>;
export type SupplierPaymentStageDataServer = BasePaymentStageDataServer<UserType.supplier, SupplierPaymentStep>;

export type PaymentStageDataServer = ManagerPaymentStageDataServer | SupplierPaymentStageDataServer;

export type PaymentAction = ManagerPaymentAction;
