import { CustomerModel, SupplierModel } from '@/entities/user';

import { ITrade, TradeServer } from '../types';

import { BaseTradeModel } from './BaseTradeModel';

export class TradeModel extends BaseTradeModel<ITrade> implements ITrade {
  static fromJson(data: TradeServer): TradeModel {
    return new TradeModel({
      id: data.id,
      stageType: data.key,
      tradeStatus: data.status,
      productTypeId: data.product_type.id,
      productId: data.product && data.product.id,
      weight: data.weight,
      ligatureWeight: data.ligature_weight,
      weightUnitId: data.weight_unit.id,
      fixingDate: new Date(data.fixing_date),
      sellAmount: this.amountFromJson(data.sell_price, data.sell_price_unit),
      sellAmountTotal: this.amountFromJson(data.sell_price_total, data.sell_price_unit),
      transitCountryCargoArrivalDate: this.dateFromJson(data.arrived_in_transit_country_at),
      transitCountryCargoShipmentDate: this.dateFromJson(data.shipped_from_transit_country_at),
      destinationCountryCargoArrivalDate: this.dateFromJson(data.arrived_at),
      cargoDeliveredDate: this.dateFromJson(data.delivered_at),
      customerPayment: data.transaction_amount_usd,
      paymentDate: this.dateFromJson(data.finished_at),
      customer: data.customer && CustomerModel.fromJson(data.customer),
      supplier: data.supplier && SupplierModel.fromJson(data.supplier),
    });
  }
}
