import { Locale } from 'antd/es/locale';
import en from 'antd/locale/en_US';
import ru from 'antd/locale/ru_RU';

import { SupportedLocale } from '../types/localization';

export const localeConfig: Record<SupportedLocale, Locale> = {
  en,
  ru: {
    ...ru,
    Table: {
      ...ru.Table,
      filterConfirm: 'Применить',
      filterReset: 'Очистить',
      filterSearchPlaceholder: 'Поиск',
    },
    Modal: {
      cancelText: 'Отменить',
      justOkText: 'Применить',
      okText: 'Создать',
    },
  },
};
