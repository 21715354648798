import { BaseResponse } from '@kts-front/types';
import { UploadFile } from 'antd';

import { Nullable } from '@/shared/types/values';

import { BaseFileServer } from '../types';

export class FileModel {
  private static mapServerToClient(file: BaseFileServer): UploadFile {
    return {
      uid: String(file.id),
      url: file.link,
      name: file.name,
    };
  }

  static fileFromJson(file: Nullable<BaseFileServer> = null): Nullable<UploadFile> {
    return file && this.mapServerToClient(file);
  }

  static fileListFromJson(server: Nullable<Array<BaseFileServer> | BaseFileServer> = null): Array<UploadFile> {
    if (!server) {
      return [];
    }

    const files = Array.isArray(server) ? server : [server];

    return files.map(this.mapServerToClient);
  }

  static async fetchFile({ url, name }: { url: string; name: string }): Promise<BaseResponse<File>> {
    try {
      const responce = await fetch(url);
      const blob = await responce.blob();

      const file = new File([blob], name, {
        type: blob.type,
      });

      return {
        isError: false,
        data: file,
      };
    } catch (e) {
      return { isError: true };
    }
  }
}
