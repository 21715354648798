import { computed, makeObservable } from 'mobx';

import {
  ManagerAwbVerifyingAction,
  ManagerShipmentAction,
  ManagerShipmentStageDataServer,
  ManagerShipmentStep,
  ManagerSpecificationVerifyingAction,
  OptionFieldsAction,
  ShipmentType,
  StageType,
} from '@/entities/stage/types';
import { UserType } from '@/entities/user';
import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';
import { ToggleModel } from '@/shared/model';
import { mapStepToNumber } from '@/shared/utils';

import { BaseManagerStageModel, BaseManagerStageModelParams } from '../../BaseManagerStageModel';
import { BaseUploadFileFieldsModel } from '../../fieldsModel/BaseUploadFileFieldsModel';
import { ConfirmationFieldsModel } from '../../fieldsModel/ConfirmationFieldsModel';
import { DestinationCountryDocsFieldsModel } from '../../fieldsModel/DestinationCountryDocsFieldsModel';
import { DocumentVerifyingFieldsModel } from '../../fieldsModel/DocumentVerifyingFieldsModel';
import { ShipmentTypeFieldsModel } from '../fieldsModel/ShipmentTypeFieldsModel';
import { TransitCountryFieldsModel } from '../fieldsModel/TransitCountryFieldsModel';

import { stepsOrder } from './config';

type Params = BaseManagerStageModelParams<ManagerShipmentStep> & {
  uploadSpecFields: BaseUploadFileFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  specVerifyingFields: DocumentVerifyingFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  shipmentTypeFields: ShipmentTypeFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  transitCountryFields: TransitCountryFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  transitCountryAwbFields: BaseUploadFileFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  transitCountryAwbVerifyingFields: DocumentVerifyingFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  destinationCountryDocsFields: DestinationCountryDocsFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  destinationCountryAwbFields: BaseUploadFileFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  cargoShipmentFields: ConfirmationFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
};

export class ManagerShipmentStageModel extends BaseManagerStageModel<StageType.shipment, ManagerShipmentStep> {
  readonly uploadSpecFields: BaseUploadFileFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  readonly specVerifyingFields: DocumentVerifyingFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  readonly shipmentTypeFields: ShipmentTypeFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  readonly cargoShipmentFields: ConfirmationFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  // StageType.transit fields
  readonly transitCountryFields: TransitCountryFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  readonly transitCountryAwbFields: BaseUploadFileFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  readonly transitCountryAwbVerifyingFields: DocumentVerifyingFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  // StageType.direct fields
  readonly destinationCountryDocsFields: DestinationCountryDocsFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  readonly destinationCountryAwbFields: BaseUploadFileFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;

  readonly deleteSupplierSpecModalState = new ToggleModel();

  constructor({
    uploadSpecFields,
    specVerifyingFields,
    shipmentTypeFields,
    cargoShipmentFields,
    transitCountryFields,
    transitCountryAwbFields,
    transitCountryAwbVerifyingFields,
    destinationCountryDocsFields,
    destinationCountryAwbFields,
    ...params
  }: Params) {
    super({
      type: StageType.shipment,
      ...params,
    });

    this.uploadSpecFields = uploadSpecFields;
    this.specVerifyingFields = specVerifyingFields;
    this.shipmentTypeFields = shipmentTypeFields;
    this.cargoShipmentFields = cargoShipmentFields;

    this.transitCountryFields = transitCountryFields;
    this.transitCountryAwbFields = transitCountryAwbFields;
    this.transitCountryAwbVerifyingFields = transitCountryAwbVerifyingFields;

    this.destinationCountryDocsFields = destinationCountryDocsFields;
    this.destinationCountryAwbFields = destinationCountryAwbFields;

    makeObservable(this, {
      shipmentTypeChoiceDisabled: computed,
      shipmentTypeChosen: computed,
    });
  }

  get currentStep(): number {
    return mapStepToNumber({ step: this.step, steps: this.steps, stepsOrder });
  }

  get steps(): ManagerShipmentStep[] {
    const steps: ManagerShipmentStep[] = [ManagerShipmentStep.uploadSpec]; // Первый шаг, есть всегда

    // Если нужна верификация спецификации, то добавляем шаг верификации
    if (this.specVerifyingFields.isNeedVerifying) {
      steps.push(ManagerShipmentStep.specVerifying);
    }

    // Шаг выбора типа отгрузки, есть всегда
    steps.push(ManagerShipmentStep.shipmentType);

    const shipmentType = this.shipmentTypeFields.shipmentType;

    // Если тип отгрузки = transit, то добавляем шаги для transit
    if (shipmentType === ShipmentType.transit) {
      steps.push(ManagerShipmentStep.transitCountry, ManagerShipmentStep.transitCountryAwb);

      // Если нужна верификация Awb, то добавляем шаг верификации Awb
      if (this.transitCountryAwbVerifyingFields.isNeedVerifying) {
        steps.push(ManagerShipmentStep.transitCountryAwbVerifying);
      }
    }

    // Если тип отгрузки = direct, то добавляем шаги для direct
    if (shipmentType === ShipmentType.direct) {
      steps.push(ManagerShipmentStep.destinationCountryDocs, ManagerShipmentStep.destinationCountryAwb);
    }

    // Если выбран тип отгрузки, то добавляем шаг подтверждения отгрузки
    if (shipmentType) {
      steps.push(ManagerShipmentStep.cargoShipment);
    }

    // Если шаг = transfer, то добавляем шаг transfer
    if (this.step === ManagerShipmentStep.transfer) {
      steps.push(ManagerShipmentStep.transfer);
    }

    return steps;
  }

  get shipmentTypeChoiceDisabled(): boolean {
    return this.step === ManagerShipmentStep.uploadSpec || this.step === ManagerShipmentStep.specVerifying;
  }

  get shipmentTypeChosen(): boolean {
    return this.shipmentTypeFields.shipmentType !== null && this.step !== ManagerShipmentStep.shipmentType;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: {
    dataServer: ManagerShipmentStageDataServer;
    tradeWorkflowStore: ITradeWorkflowStore;
  }): ManagerShipmentStageModel {
    const { current_step, steps, supplier_specification } = dataServer;
    const isSpecVerifyingStep =
      current_step === ManagerShipmentStep.uploadSpec &&
      steps.upload_shipment_specification?.document?.uploaded_by.role === UserType.supplier;

    return new ManagerShipmentStageModel({
      uploadSpecFields: BaseUploadFileFieldsModel.fromJson<ManagerShipmentStep, ManagerShipmentAction>({
        data:
          steps[ManagerShipmentStep.uploadSpec] ??
          (supplier_specification ? { document: supplier_specification ?? null } : null),
        step: ManagerShipmentStep.uploadSpec,
        approveAction: ManagerShipmentStep.uploadSpec,
        rejectAction: OptionFieldsAction.deleteSupplierSpec,
        tradeWorkflowStore,
      }),
      specVerifyingFields: DocumentVerifyingFieldsModel.fromJson({
        data: steps[ManagerShipmentStep.uploadSpec],
        step: ManagerShipmentStep.specVerifying,
        approveAction: ManagerSpecificationVerifyingAction.approveSpecification,
        rejectAction: ManagerSpecificationVerifyingAction.rejectSpecification,
        tradeWorkflowStore,
      }),
      shipmentTypeFields: ShipmentTypeFieldsModel.fromJson({
        data: steps[ManagerShipmentStep.shipmentType]?.type,
        step: ManagerShipmentStep.shipmentType,
        approveAction: ManagerShipmentStep.shipmentType,
        tradeWorkflowStore,
      }),
      cargoShipmentFields: ConfirmationFieldsModel.fromJson({
        data: steps[ManagerShipmentStep.cargoShipment]?.shipped,
        step: ManagerShipmentStep.cargoShipment,
        approveAction: ManagerShipmentStep.cargoShipment,
        tradeWorkflowStore,
      }),
      transitCountryFields: TransitCountryFieldsModel.fromJson({
        data: steps.select_transit_country?.id,
        step: ManagerShipmentStep.transitCountry,
        approveAction: ManagerShipmentStep.transitCountry,
        tradeWorkflowStore,
      }),
      transitCountryAwbFields: BaseUploadFileFieldsModel.fromJson({
        data: steps[ManagerShipmentStep.transitCountryAwb],
        step: ManagerShipmentStep.transitCountryAwb,
        approveAction: ManagerShipmentStep.transitCountryAwb,
        tradeWorkflowStore,
      }),
      transitCountryAwbVerifyingFields: DocumentVerifyingFieldsModel.fromJson({
        data: steps[ManagerShipmentStep.transitCountryAwb],
        step: ManagerShipmentStep.transitCountryAwbVerifying,
        approveAction: ManagerAwbVerifyingAction.approveAwb,
        rejectAction: ManagerAwbVerifyingAction.rejectAwb,
        tradeWorkflowStore,
      }),
      destinationCountryDocsFields: DestinationCountryDocsFieldsModel.fromJson({
        shipmentType: ShipmentType.direct,
        data: steps[ManagerShipmentStep.destinationCountryDocs],
        step: ManagerShipmentStep.destinationCountryDocs,
        approveAction: ManagerShipmentStep.destinationCountryDocs,
        tradeWorkflowStore,
      }),
      destinationCountryAwbFields: BaseUploadFileFieldsModel.fromJson({
        data: steps[ManagerShipmentStep.destinationCountryAwb],
        step: ManagerShipmentStep.destinationCountryAwb,
        approveAction: ManagerShipmentStep.destinationCountryAwb,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
      step: isSpecVerifyingStep ? ManagerShipmentStep.specVerifying : current_step,
    });
  }
}
