import { Flex } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import FormInput from '@/shared/ui/form/FormInput';
import PhoneInput from '@/shared/ui/form/PhoneInput';

import { ClientEditFiledsModel, ClientFieldsModel } from '../../model/ClientFieldsModel';

import s from './ProfileFields.module.scss';

type Props = {
  fields: ClientFieldsModel | ClientEditFiledsModel;
  className?: string;
  emailDisabled?: boolean;
};

const ProfileFields: React.FC<Props> = ({ fields, className, emailDisabled }) => {
  if (fields instanceof ClientEditFiledsModel) {
    return (
      <Flex vertical gap={16} className={cn(className, s.content)}>
        <FormInput model={fields.email} disabled={emailDisabled} />
        <FormInput model={fields.name} />
        <PhoneInput model={fields.phone} />
      </Flex>
    );
  }

  return (
    <>
      <FormInput size="large" model={fields.email} disabled={emailDisabled} />
      <PhoneInput size="large" model={fields.phone} />
    </>
  );
};

export default observer(ProfileFields);
