import { BaseResponse } from '@kts-front/types';

import { apiStore, apiUrls } from '@/shared/api';
import { ListModel } from '@/shared/model';

import { IDictionaryStore } from '../../types/client';
import { SingatoryDictionaryListResponse } from '../../types/server';
import { SignatoryModel } from '../SignatoryModel';

import { defaultParams } from './config';

export class SignatoriesStore implements IDictionaryStore<SignatoryModel> {
  readonly list = new ListModel<SignatoryModel, number>();

  async load(): Promise<BaseResponse> {
    const response = await this._signatoryListRequest.call({
      params: defaultParams,
    });

    if (response.isError) {
      return { isError: true };
    }

    this.list.fillByRawData(
      response.data.results,
      (raw) => ({
        entity: SignatoryModel.fromJson(raw),
        key: raw.id,
      }),
      true,
    );

    return { isError: false };
  }

  private readonly _signatoryListRequest = apiStore.createRequest<SingatoryDictionaryListResponse>({
    url: apiUrls.dictionaries.signatoryList,
  });
}
