import { TFunctionType, TranslationString } from '../types/localization';

/**
 * Рендерит строку, исходя из языка, для рендера в компонентах необходимо использовать хук useTranslationString
 * @param str
 * @param t
 * @returns {string}
 */
export const renderTranslationString = (str: TranslationString, t: TFunctionType): string => {
  if (typeof str === 'function') {
    // @ts-ignore
    return str(t);
  }

  return str;
};

/**
 * Сравнивает две TranslationString строки
 * @param str1
 * @param str2
 * @param t
 * @returns {boolean}
 */
export const compareTranslationStrings = (
  str1: TranslationString | string,
  str2: TranslationString | string,
  t: TFunctionType,
): boolean => {
  const text1 = renderTranslationString(str1, t);
  const text2 = renderTranslationString(str2, t);

  return text1 === text2;
};
