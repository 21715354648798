import { computed, makeObservable } from 'mobx';

import { DocumentFileType, FileModel } from '@/entities/file';
import { TradeCloseSupplierDocsServer } from '@/entities/stage';
import { UploadFileModel } from '@/shared/model/form/UploadFileModel';
import { Nullable } from '@/shared/types/values';

import { BaseFieldsModel, BaseFieldsModelParams } from '../../../fieldsModel/BaseFieldsModel';

type Params<Step, Action> = BaseFieldsModelParams<Step, Action> & {
  signedCertificate: UploadFileModel;
  additionalAgreement: UploadFileModel;
};

type Payload<Action> = {
  action: Action;
  files: File[];
  doc_types: Array<keyof TradeCloseSupplierDocsServer>;
};

export class TradeCloseSupplierDocsFieldsModel<Step, Action> extends BaseFieldsModel<Step, Action, Payload<Action>> {
  readonly signedCertificate: UploadFileModel;
  readonly additionalAgreement: UploadFileModel;

  constructor({ signedCertificate, additionalAgreement, ...params }: Params<Step, Action>) {
    super(params);

    this.signedCertificate = signedCertificate;
    this.additionalAgreement = additionalAgreement;

    makeObservable(this, {
      approveDisabled: computed,
    });
  }

  get approveDisabled(): boolean {
    return (
      this.signedCertificate.isError ||
      !this.signedCertificate.isUploaded ||
      this.additionalAgreement.isError ||
      !this.additionalAgreement.isUploaded
    );
  }

  toApproveJson() {
    const files = [
      this.signedCertificate.value[0] && this.signedCertificate.value[0].originFileObj,
      this.additionalAgreement.value[0] && this.additionalAgreement.value[0].originFileObj,
    ];

    if (files.some((file) => !file)) {
      return null;
    }

    return {
      action: this.approveAction,
      files,
      doc_types: [DocumentFileType.supplierAcceptanceCertificate, DocumentFileType.supplierAdditionalAgreement],
    } as Payload<Action>;
  }

  static fromJson<Step, Action>({
    data,
    ...params
  }: {
    data?: Nullable<TradeCloseSupplierDocsServer>;
  } & BaseFieldsModelParams<Step, Action>): TradeCloseSupplierDocsFieldsModel<Step, Action> {
    return new TradeCloseSupplierDocsFieldsModel({
      signedCertificate: new UploadFileModel({
        initialValue: FileModel.fileListFromJson(data?.[DocumentFileType.supplierAcceptanceCertificate]),
        required: true,
        description: (t) =>
          t('manager.tradeCloseSupplierDocs.descriptions.signedCertificate', { ns: 'transportationStage' }),
      }),
      additionalAgreement: new UploadFileModel({
        initialValue: FileModel.fileListFromJson(data?.[DocumentFileType.supplierAdditionalAgreement]),
        required: true,
        description: (t) =>
          t('manager.tradeCloseSupplierDocs.descriptions.additionalAgreement', { ns: 'transportationStage' }),
      }),
      ...params,
    });
  }
}
