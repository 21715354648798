import { Button, Flex } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { CustomModal, TransComp } from '@/shared/ui';
import { CustomUpload } from '@/shared/ui/form/CustomUpload';
import { FormItem } from '@/shared/ui/form/FormItem';
import { UploadButton } from '@/shared/ui/form/buttons';

import { GenerationFieldsModel, UploadOrGenerationDocumentModel } from '../../model';
import { DocumentFileType } from '../../types';

import s from './UploadOrGenerationDocument.module.scss';

type UploadOrGenerationDocumentProps<
  DocType extends DocumentFileType,
  Payload,
  FieldsModel extends GenerationFieldsModel<DocType, Payload>,
> = {
  className?: string;
  model: UploadOrGenerationDocumentModel<DocType, Payload, FieldsModel>;
  disabled?: boolean;
  FieldsComponent: React.ComponentType<{
    model: FieldsModel;
  }>;
};

const UploadOrGenerationDocument = <
  DocType extends DocumentFileType,
  Payload,
  FieldsModel extends GenerationFieldsModel<DocType, Payload>,
>({
  className,
  model,
  disabled,
  FieldsComponent,
}: UploadOrGenerationDocumentProps<DocType, Payload, FieldsModel>) => {
  const {
    canBeGenerated,
    fileName,
    isUploaded,
    isOpenModal,
    generationFields,
    pollingState,
    openModal,
    closeModal,
    generate,
  } = model;

  return (
    <>
      <CustomModal
        title={fileName}
        open={isOpenModal}
        onCancel={closeModal}
        cancelButtonProps={{ disabled: generationFields.loadingState.isLoading }}
        onOk={generate}
        okText={(t) => t('buttons.create', { ns: 'shared' })}
        okButtonProps={{
          loading: generationFields.loadingState.isLoading,
          disabled: !generationFields.isFilled || generationFields.isError,
        }}
      >
        <FieldsComponent model={generationFields} />
      </CustomModal>
      <FormItem className={cn(s.upload, className)} model={model}>
        <Flex gap={8} flex={1}>
          <CustomUpload
            className={cn(isUploaded && s.upload_fullWidth)}
            {...model.props}
            disabled={disabled || pollingState.isLoading || generationFields.loadingState.isLoading}
          >
            {model.props.openFileDialogOnClick && (
              <UploadButton
                disabled={
                  disabled || model.props.disabled || pollingState.isLoading || generationFields.loadingState.isLoading
                }
              />
            )}
          </CustomUpload>
          {canBeGenerated && (
            <Button
              size="small"
              loading={pollingState.isLoading || generationFields.loadingState.isLoading}
              disabled={disabled}
              onClick={openModal}
            >
              <TransComp>{(t) => t('buttons.generate', { ns: 'shared' })}</TransComp>
            </Button>
          )}
        </Flex>
      </FormItem>
    </>
  );
};

export default observer(UploadOrGenerationDocument);
