import { StageType } from '@/entities/stage';

export const trade = {
  en: {
    header: {
      title: 'Trade № {{tradeId}}',
      trades: 'Trades',
    },
    tabs: {
      actions: {
        searchLine: 'Find document',
        roleMenu: 'Filter by role',
        docsArchiveLink: 'Download archive',
      },
      currentStep: {
        title: 'Current step',
      },
      info: {
        title: 'Information',
        fields: {
          transitCountryForManager: 'Transit country',
          transitCountryForOther: 'Destination country',
          transitLogisticCompany: 'Transit logistic company',
          destinationLogisticCompany: 'Destination logistic company',
          trackingLink: 'Track cargo',
        },
      },
      documents: {
        title: 'Documents',
        uploadButton: 'Upload document',
        emptyListText: 'No documents have been uploaded yet',
        filteredEmptyListText:
          'No results were found based on your search or filtering. Change your request and try again.',
        docTypeLabel: 'Document type',
        docTypePlaceholder: 'Select document type',
        uploadModalTitle: "Uploading deal's document",
      },
      specification: {
        title: 'Specification',
        uploadButton: 'Upload specification',
        emptyListText: 'No specifications have been uploaded yet',
        docTypeLabel: 'Specification type',
        docTypePlaceholder: 'Select specification type',
        uploadModalTitle: 'Uploading specification',
        uploadError: 'Error uploading specification',
        uploadSuccess: 'Specification uploaded successfully',
        updateError: 'Error updating specification',
        updateSuccess: 'Specification updated successfully',
      },
      updates: {
        title: 'Updates',
        emptyListText: 'No updates available',
      },
    },
    transfer: {
      title: 'Transfer to next stage',
      description: 'Complete the stage by clicking on the button “Transfer\u00A0to\u00A0{{ stage }}“',
      buttonText: 'Transfer to {{ stage }}',
      // map current stage to next stage
      [StageType.creation]: 'Confirmation',
      [StageType.confirmation]: 'Payment',
      [StageType.payment]: 'Shipment',
      [StageType.shipment]: 'Transportation',
      [StageType.transportation]: 'Complete Deal',
      [StageType.finalization]: '',
    },
  },
  ru: {
    header: {
      title: 'Сделка № {{tradeId}}',
      trades: 'Сделки',
    },
    tabs: {
      actions: {
        searchLine: 'Найти документ',
        roleMenu: 'Фильтровать по роли',
        docsArchiveLink: 'Скачать архив',
      },
      currentStep: {
        title: 'Текущий шаг',
      },
      info: {
        title: 'Информация',
        fields: {
          transitCountryForManager: 'Страна транзита',
          transitCountryForOther: 'Страна назначения',
          transitLogisticCompany: 'Логисты в стране транзита',
          destinationLogisticCompany: 'Логисты в стране назначения',
          trackingLink: 'Отслеживать груз',
        },
      },
      documents: {
        title: 'Документы',
        uploadButton: 'Загрузить документ',
        emptyListText: 'Документы еще не были загружены',
        filteredEmptyListText:
          'По вашему запросу или фильтрации ничего не найдено. Измените запрос и попробуйте снова.',
        docTypeLabel: 'Тип документа',
        docTypePlaceholder: 'Выберите тип документа',
        uploadModalTitle: 'Загрузка документа по сделке',
      },
      specification: {
        title: 'Спецификация',
        uploadButton: 'Загрузить спецификацию',
        emptyListText: 'Спецификации еще не были загружены',
        docTypeLabel: 'Тип спецификации',
        docTypePlaceholder: 'Выберите тип спецификации',
        uploadModalTitle: 'Загрузка спецификации',
        uploadError: 'Ошибка загрузки спецификации',
        uploadSuccess: 'Спецификация успешно загружена',
        updateError: 'Ошибка обновления спецификации',
        updateSuccess: 'Спецификация успешно обновлена',
      },
      updates: {
        title: 'Обновления',
        emptyListText: 'Обновления отсутствуют',
      },
    },
    transfer: {
      title: 'Перейдите на следующий этап',
      description: 'Завершите этап, нажав на кнопку “Перейти\u00A0к\u00A0{{ stage }}“',
      buttonText: 'Перейти к {{ stage }}',
      // map current stage to next stage
      [StageType.creation]: 'Подтверждению',
      [StageType.confirmation]: 'Оплате',
      [StageType.payment]: 'Отгрузке',
      [StageType.shipment]: 'Перевозке',
      [StageType.transportation]: 'Завершению\u00A0сделки',
      [StageType.finalization]: '',
    },
  },
};
