import { useToggleState } from '@kts-front/hooks';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useMatch } from 'react-router';

import { routerUrls } from '@/shared/config/routes';
import { useWidth } from '@/shared/hooks/useWidth';

import { SideMenu } from '../SideMenu';
import SideMenuNavBar from '../SideMenuNavBar';

import s from './SideMenuPageLayout.module.scss';

type SideMenuPageLayoutProps = React.PropsWithChildren;

const SideMenuPageLayout: React.FC<SideMenuPageLayoutProps> = ({ children }) => {
  const isMainPage = useMatch(routerUrls.main.mask);
  const isLoginPage = useMatch(routerUrls.login.mask);
  const isCreatePasswordPage = useMatch(routerUrls.createPassword.mask);

  const { toggle: toggleMenuOpen, close: closeMenu, opened: isMenuOpened } = useToggleState(true);
  const { isDesktop } = useWidth();

  React.useLayoutEffect(() => {
    if (!isDesktop) {
      closeMenu();
    }
  }, [isDesktop]);

  if (isLoginPage || isCreatePasswordPage || isMainPage) {
    return children;
  }

  return (
    <div className={s['page-layout']}>
      {isDesktop ? (
        <SideMenu
          className={cn(s['page-layout__menu-desktop'])}
          isMenuOpened={isMenuOpened}
          toggleMenuOpen={toggleMenuOpen}
        />
      ) : (
        <SideMenuNavBar isMenuOpened={isMenuOpened} toggleMenuOpen={toggleMenuOpen} />
      )}
      <div className={cn(s['page-layout__content'], !isMenuOpened && s['page-layout__content_wider'])}>{children}</div>
    </div>
  );
};

export default observer(SideMenuPageLayout);
