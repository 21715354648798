import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { StageModel, StepComponentProps } from '@/entities/stage';
import { BaseUploadFileFieldsModel } from '@/entities/stage/model/fieldsModel/BaseUploadFileFieldsModel';
import { CustomAlert, StepDescription } from '@/shared/ui';
import FormUpload from '@/shared/ui/form/FormUpload';
import { ApproveButton } from '@/shared/ui/form/buttons';

type Props<StageModel> = StepComponentProps<StageModel>;

const UploadFileWithRejectAlertStep = <Model extends StageModel>({ model, fieldKey, description }: Props<Model>) => {
  const fields = fieldKey && model[fieldKey];

  if (!(fields instanceof BaseUploadFileFieldsModel)) {
    return null;
  }

  return (
    <StepDescription description={description}>
      {fields.isRejected && (
        <CustomAlert
          type="error"
          message={(t) => t('messages.rejectedDocError', { ns: 'file' })}
          description={fields.error}
        />
      )}
      <FormUpload
        model={fields.file}
        disabled={fields.isLoading || fields.isDisabled || fields.file.isInitializedByValue}
      />
      <ApproveButton
        loading={fields.isLoading}
        disabled={fields.approveDisabled || fields.isDisabled || fields.file.isInitializedByValue}
        onClick={fields.approveStep}
      />
    </StepDescription>
  );
};

export default observer(UploadFileWithRejectAlertStep);
