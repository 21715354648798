import { Flex } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { DocumentFileType } from '@/entities/file';
import FormDatePicker from '@/shared/ui/form/FormDatePicker';
import FormInput from '@/shared/ui/form/FormInput';
import FormSelect from '@/shared/ui/form/FormSelect';

import { incotermOptions, packingOptions } from '../../config';
import { InvoiceFiedsModel } from '../../model/fieldsModel/InvoiceModel';

type InvoiceFormProps<DocType extends DocumentFileType> = {
  model: InvoiceFiedsModel<DocType>;
};

const InvoiceForm = <DocType extends DocumentFileType>({ model }: InvoiceFormProps<DocType>) => {
  const { isNeedCountryId, date, countryId, flightNumber, packing, incoterms, signatureId, loadingState } = model;

  const { dictionariesStore } = useRootStore();

  return (
    <Flex vertical gap={16}>
      <FormDatePicker model={date} disabled={loadingState.isLoading} />
      {isNeedCountryId && (
        <FormSelect
          model={countryId}
          options={dictionariesStore.countries.list.items}
          disabled={loadingState.isLoading}
        />
      )}
      <FormInput model={flightNumber} disabled={loadingState.isLoading} />
      <FormSelect model={packing} options={packingOptions} disabled={loadingState.isLoading} />
      <FormSelect model={incoterms} options={incotermOptions} disabled={loadingState.isLoading} />
      <FormSelect
        model={signatureId}
        options={dictionariesStore.signatories.list.items}
        disabled={loadingState.isLoading}
      />
    </Flex>
  );
};

export default observer(InvoiceForm);
