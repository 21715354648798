import { ArgsProps } from 'antd/es/message';

import { TranslationString } from '@/shared/types/localization';

export enum MessageType {
  error = 'error',
  success = 'success',
  info = 'info',
  warning = 'warning',
}

export interface IMessage extends Omit<ArgsProps, 'className' | 'content' | 'key' | 'type'> {
  message: TranslationString;
  type: MessageType;
}
