import { computed, makeObservable } from 'mobx';

import { TranslationString } from '@/shared/types/localization';
import { ValidatorResult } from '@/shared/types/validator';

import { BaseFieldModel, BaseFieldModelParams } from './BaseFieldModel';

export enum InputType {
  text = 'text',
  number = 'number',
  email = 'email',
  phone = 'phone',
}

export type InputModelParams<Value> = BaseFieldModelParams<Value> & {
  type?: InputType;
  formatter?: (value: Value) => Value;
  parser?: (value: string) => Value;
};

type InputModelProps<V> = {
  value: V;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: VoidFunction;
  disabled: boolean;
  required: boolean;
  error: ValidatorResult;
  type: InputType;
  placeholder: TranslationString;
};

export class InputModel<Value = string> extends BaseFieldModel<Value> {
  readonly type: InputType;
  readonly formatter?: (value: Value) => Value;
  readonly parser?: (value: string) => Value;

  constructor({ type = InputType.text, formatter, parser, ...params }: InputModelParams<Value>) {
    super(params);

    this.type = type;
    this.formatter = formatter;
    this.parser = parser;

    makeObservable(this, {
      props: computed,
    });
  }

  get props(): InputModelProps<Value> {
    return {
      value: this.formatter && this._value ? this.formatter(this._value) : this._value,
      onChange: (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        const value = this.parser ? this.parser(e.currentTarget.value) : e.currentTarget.value;
        this.change(value as Value);
      },
      onBlur: this.ignoreOnBlurValidation ? undefined : this.validate,
      disabled: this.disabled,
      required: this.required,
      error: this.error,
      type: this.type,
      placeholder: this.placeholder,
    };
  }
}
