export const optionalFieldsServer = [
  'arbitrary_documents',
  'authorization_letter',
  'destination_logistic_company',
  'finalization_photo',
  'indirect_taxes_payment_certificate',
  'supplier_specification',
  'tracking_link',
  'transit_logistic_company',
  'warranty_loa',
] as const;
