import { SelectProps } from 'antd/es/select';

const ckeckValueAsStringOrNumber = (value: React.ReactNode): value is string | number => {
  return typeof value === 'string' || typeof value === 'number';
};

export const filterOption: SelectProps['filterOption'] = (input, option) => {
  return ckeckValueAsStringOrNumber(option?.name) && String(option.name).toLowerCase().includes(input.toLowerCase());
};

export const filterSort: SelectProps['filterSort'] = (optionA, optionB) => {
  return ckeckValueAsStringOrNumber(optionA.name) && ckeckValueAsStringOrNumber(optionB.name)
    ? String(optionA.name).toLowerCase().localeCompare(String(optionB.name).toLowerCase())
    : 0;
};
