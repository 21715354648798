import { BaseResponse } from '@kts-front/types';

import { DictionaryField, DictionaryModel, IDictionariesStore } from '@/entities/dictionary';
import { ClientsListResponse, UserType } from '@/entities/user';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel } from '@/shared/model';

import { ClientsStore } from './ClientsStore';
import { CountriesStore } from './CountriesStore';
import { CurrenciesStore } from './CurrenciesStore';
import { LegalCompaniesStore } from './LegalCompaniesStore';
import { LogisticCompaniesStore } from './LogisticCompaniesStore';
import { ProductTypesStore } from './ProductTypesStore';
import { SignatoriesStore } from './SignatoriesStore';
import { defaultParams } from './config';

export class DictionariesStore implements IDictionariesStore {
  readonly legalCompanies = new LegalCompaniesStore();
  readonly logisticCompanies = new LogisticCompaniesStore();
  readonly currencies = new CurrenciesStore();
  readonly productTypes = new ProductTypesStore();
  readonly countries = new CountriesStore();
  readonly signatories = new SignatoriesStore();
  readonly customers: ClientsStore;
  readonly suppliers: ClientsStore;

  readonly loadingStage = new LoadingStageModel();
  readonly loadingClientsStage = new LoadingStageModel();

  private readonly clientListRequest = apiStore.createRequest<ClientsListResponse>({
    url: apiUrls.client.list,
  });

  constructor() {
    this.customers = new ClientsStore(this);
    this.suppliers = new ClientsStore(this);
  }

  loadClients = async (): Promise<BaseResponse> => {
    const response = await this.clientListRequest.call({
      params: defaultParams,
    });

    if (response.isError) {
      return { isError: true };
    }

    this.customers.list.reset();
    this.suppliers.list.reset();

    response.data.results.forEach((client) => {
      if (client.type === UserType.customer) {
        this.customers.list.addEntity({ entity: DictionaryModel.fromJson(client), key: client.id });
      } else if (client.type === UserType.supplier) {
        this.suppliers.list.addEntity({ entity: DictionaryModel.fromJson(client), key: client.id });
      }
    });

    return { isError: false };
  };

  async loadDictionaries(fields: DictionaryField[]): Promise<BaseResponse> {
    if (this.loadingStage.isLoading) {
      return { isError: true };
    }

    this.loadingStage.loading();

    const responses = await Promise.all(fields.map((field) => this[field].load()));

    if (responses.some((response) => response.isError)) {
      this.loadingStage.error();

      return { isError: true };
    } else {
      this.loadingStage.success();

      return { isError: false };
    }
  }
}
