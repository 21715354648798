import { TranslationString } from '@/shared/types/localization';
import { Options } from '@/shared/types/values';

import { NotificationCategory, NotificationService } from './server';

const T_OPTIONS = { ns: 'notifications' } as const;

export const NotificationCategoryToLabel: Record<NotificationCategory, TranslationString> = {
  [NotificationCategory.statusChange]: (t) => t('category.trade_status_change', T_OPTIONS),
  [NotificationCategory.docUpload]: (t) => t('category.document_upload', T_OPTIONS),
  [NotificationCategory.docVerification]: (t) => t('category.document_verification', T_OPTIONS),
};

export const notificationCategoryOptions: Options<NotificationCategory> = [
  NotificationCategory.statusChange,
  NotificationCategory.docUpload,
  NotificationCategory.docVerification,
].map((category) => ({
  label: NotificationCategoryToLabel[category],
  value: category,
}));

export const notificationCategories: Array<NotificationCategory> = [
  NotificationCategory.statusChange,
  NotificationCategory.docUpload,
  NotificationCategory.docVerification,
];

export const notificationServices: Array<NotificationService> = [
  NotificationService.telegram,
  NotificationService.email,
];
