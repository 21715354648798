import dayjs from 'dayjs';

import { TranslationString } from '@/shared/types/localization';
import { ValidatorResult } from '@/shared/types/validator';
import { Nullable } from '@/shared/types/values';

import { BaseFieldModel, BaseFieldModelParams } from './BaseFieldModel';

type DatePickerModelParams = BaseFieldModelParams<Date | null>;

type DatePickerModelProps = {
  value: Nullable<dayjs.Dayjs>;
  onChange: (dayjs: Nullable<dayjs.Dayjs>) => void;
  onBlur?: VoidFunction;
  disabled: boolean;
  required: boolean;
  error: ValidatorResult;
  placeholder: TranslationString;
};

export class DatePickerModel extends BaseFieldModel<Nullable<Date>> {
  constructor(params: DatePickerModelParams) {
    super(params);
  }

  get props(): DatePickerModelProps {
    return {
      value: this._value && dayjs(this._value),
      onChange: (dayjs: Nullable<dayjs.Dayjs>) => {
        const date = dayjs && new Date(dayjs.format('YYYY-MM-DD'));

        this.change(date);

        if (!date) {
          this.validate();
        }
      },
      onBlur: this.ignoreOnBlurValidation ? undefined : this.validate,
      disabled: this.disabled,
      required: this.required,
      error: this.error,
      placeholder: this.placeholder,
    };
  }
}
