import { Alert, AlertProps } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { TranslationNode } from '@/shared/types/localization';

import { TransComp } from '../TransComp';

import s from './CustomAlert.module.scss';

type Props = Omit<AlertProps, 'banner' | 'message' | 'description'> & {
  message?: TranslationNode;
  description?: TranslationNode;
};

const CustomAlert: React.FC<Props> = ({ className, message, description, ...props }) => {
  return (
    <div className={cn(s.alert, className)}>
      <Alert
        {...props}
        message={<TransComp>{message}</TransComp>}
        description={<TransComp>{description}</TransComp>}
        banner
      />
    </div>
  );
};

export default React.memo(CustomAlert);
