import { InboxOutlined } from '@ant-design/icons';
import { Typography, Upload } from 'antd';
import { DraggerProps } from 'antd/es/upload';
import cn from 'classnames';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks/useTranslation';

import s from './UploadDragger.module.scss';

const BEFORE_UPLOAD = () => false;

const UploadDragger: React.FC<DraggerProps> = ({ className, openFileDialogOnClick = true, ...props }) => {
  const { t } = useTypedTranslation('shared');

  return (
    <Upload.Dragger
      className={cn(s.dragger, !openFileDialogOnClick && s.dragger_disabledFileDialog, className)}
      openFileDialogOnClick={openFileDialogOnClick}
      {...props}
      beforeUpload={BEFORE_UPLOAD}
    >
      <InboxOutlined className={s.dragger_icon} />
      <Typography.Title className="dragger-title" level={5}>
        {t('components.UploadDragger.title')}
      </Typography.Title>
      <Typography.Text type="secondary">{t('components.UploadDragger.subTitle')}</Typography.Text>
    </Upload.Dragger>
  );
};

export default UploadDragger;
