import * as React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import { ErrorBoundary } from '@/pages/ErrorBoundary';
import { routerUrls } from '@/shared/config/routes';

import { App } from '../App';
import { withAuthCheck } from '../hocs/withAuthCheck';
import { withDictionaries } from '../hocs/withDictionaries';

import {
  profilePageRoleDictionariesMap,
  tradesPageRoleDictionariesMap,
  tradeWorkflowPageRoleDictionariesMap,
} from './config';

/* Страницы с предзагруженными словарями */
const TradesPageWithDictionaries = withDictionaries({
  Component: React.lazy(() => import('@/pages/Trades')),
  dictionariesMap: tradesPageRoleDictionariesMap,
});
const TradeWorkflowPageWithDictionaries = withDictionaries({
  Component: React.lazy(() => import('@/pages/TradeWorkflow')),
  dictionariesMap: tradeWorkflowPageRoleDictionariesMap,
});

const ProfilePageWithDictionaries = withDictionaries({
  Component: React.lazy(() => import('@/pages/Profile')),
  dictionariesMap: profilePageRoleDictionariesMap,
});

/* Страницы доступные только авторизованным пользователям */
const MainPage = withAuthCheck({ Component: React.lazy(() => import('@/pages/Main')) });
const ClientsPage = withAuthCheck({ Component: React.lazy(() => import('@/pages/Clients')) });
const AgentsPage = withAuthCheck({ Component: React.lazy(() => import('@/pages/Agents')) });
const TradesPage = withAuthCheck({ Component: TradesPageWithDictionaries });
const TradeWorkflowPage = withAuthCheck({ Component: TradeWorkflowPageWithDictionaries });
const DocumentsPage = withAuthCheck({ Component: React.lazy(() => import('@/pages/Documents')) });
const ProfilePage = withAuthCheck({ Component: ProfilePageWithDictionaries });

const EditProfile = withAuthCheck({ Component: React.lazy(() => import('@/features/profile/EditProfile')) });
const EditCompany = withAuthCheck({ Component: React.lazy(() => import('@/features/profile/EditCompany')) });

const NotificationList = withAuthCheck({
  Component: React.lazy(() => import('@/features/notifications/NotificationList/ui')),
});
const NotificationsSettings = withAuthCheck({
  Component: React.lazy(() => import('@/features/notifications/NotificationsSettings/ui')),
});

/* Страницы доступные только неавторизованным пользователям */
const LoginPage = withAuthCheck({ Component: React.lazy(() => import('@/pages/Login')), needAuth: false });
const CreatePasswordPage = withAuthCheck({
  Component: React.lazy(() => import('@/pages/CreatePassword')),
  needAuth: false,
});

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: routerUrls.main.mask,
        element: <MainPage />,
      },
      {
        path: routerUrls.login.mask,
        element: <LoginPage />,
      },
      {
        path: routerUrls.createPassword.mask,
        element: <CreatePasswordPage />,
      },
      {
        path: routerUrls.clients.mask,
        element: <ClientsPage />,
      },
      {
        path: routerUrls.agents.mask,
        element: <AgentsPage />,
      },
      {
        path: routerUrls.trades.mask,
        element: <TradesPage />,
      },
      {
        path: routerUrls.tradeWorkflow.mask,
        element: <TradeWorkflowPage />,
      },
      {
        path: routerUrls.documents.mask,
        element: <DocumentsPage />,
      },
      {
        path: routerUrls.profile.mask,
        element: <ProfilePage />,
        children: [
          {
            path: routerUrls.profile.children.me.mask,
            element: <EditProfile />,
          },
          {
            path: routerUrls.profile.children.company.mask,
            element: <EditCompany />,
          },
          {
            path: routerUrls.profile.children.notifications.mask,
            children: [
              {
                path: routerUrls.profile.children.notifications.mask,
                element: <NotificationList />,
              },
              {
                path: routerUrls.profile.children.notifications.children.settings.mask,
                element: <NotificationsSettings />,
              },
              {
                path: '*',
                element: <Navigate to={routerUrls.profile.children.notifications.mask} replace />,
              },
              {
                path: routerUrls.profile.children.notifications.mask,
                element: <Navigate to={routerUrls.profile.children.notifications.mask} replace />,
              },
            ],
          },
          {
            path: '*',
            element: <Navigate to={routerUrls.profile.children.me.mask} replace />,
          },
          {
            path: routerUrls.profile.mask,
            element: <Navigate to={routerUrls.profile.children.me.mask} replace />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to={'/'} replace />,
      },
    ],
  },
]);

export default router;
