import { Upload, UploadProps } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './CustomUpload.module.scss';

const BEFORE_UPLOAD = () => false;

const CustomUpload: React.FC<UploadProps> = ({ className, ...props }) => {
  return (
    <Upload
      className={cn(s.upload, !props.fileList?.length && s.upload_no_files, className)}
      {...props}
      beforeUpload={BEFORE_UPLOAD}
    />
  );
};

export default CustomUpload;
