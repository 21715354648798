import { DocumentFileType } from '@/entities/file';

export const transportationStage = {
  en: {
    manager: {
      transitCountryCargoArrival: {
        title: 'Arrival of cargo',
        description: 'When the cargo arrives in the transit country, confirm arrival',
      },
      cargoArrival: {
        title: 'Arrival of cargo',
        description: 'When the cargo arrives in the destination country, confirm arrival',
      },
      tradeCloseSupplierDocs: {
        title: 'Upload documents for closing the trade with the supplier',
        descriptions: {
          signedCertificate: 'Upload the statement, signed on both sides',
          additionalAgreement: 'Upload additional agreement',
        },
      },
      destinationCountryDocs: {
        title: 'Upload documents for destination country',
        descriptions: {
          exportInvoice: 'Upload invoice for export 4%',
          customerCommercialInvoice: 'Upload the commercial invoice for the customer',
          customerSpecification: 'Upload the specification for the customer on our company letterhead',
          customerAcceptanceCertificate: 'Upload acceptance certificate for the customer',
          customerAdditionalAgreement: 'Additional agreement for the customer',
        },
      },
      destinationCountryAwb: {
        title: 'Upload AWB for destination country',
        description: 'Upload AWB for destination country and click “Approve“',
      },
      transitCountryCargoShipment: {
        title: 'Shipment of cargo',
        description: 'When the shipment leaves the transit country, confirm shipment',
      },
      destinationCountryCargoArrival: {
        title: 'Arrival of cargo',
        description: 'When the cargo arrives in the destination country, confirm arrival',
      },
    },
    customer: {
      cargoArrival: {
        title: 'Waiting for the cargo to arrive',
        description: 'Wait until the manager marks the arrival of the cargo in the destination country',
      },
      destinationCountryDocs: {
        title: 'Wait for the deal documents to load',
        waitDescription: 'Once the documents are uploaded they will appear here',
        approveDescription: 'Documents uploaded',
        docsDescription: {
          [DocumentFileType.customerCommercialInvoice]: 'Invoice',
          [DocumentFileType.customerSpecification]: 'Specification',
          [DocumentFileType.customerAcceptanceCertificate]: 'Transfer acceptance certificate signed on one side',
          [DocumentFileType.customerAdditionalAgreement]: 'Additional agreement',
        },
      },
      destinationCountryAwb: {
        title: 'Wait for the AWB to load',
        waitDescription: 'Once the AWB is uploaded it will appear here',
        approveDescription: 'AWB uploaded',
      },
      transfer: {
        title: 'The cargo has arrived',
      },
    },
  },
  ru: {
    manager: {
      transitCountryCargoArrival: {
        title: 'Прибытие груза',
        description: 'Когда груз прибудет в страну транзита, подтвердите прибытие',
      },
      cargoArrival: {
        title: 'Прибытие груза',
        description: 'Когда груз прибудет в страну назначения, подтвердите прибытие',
      },
      tradeCloseSupplierDocs: {
        title: 'Загрузите документы для закрытия сделки с поставщиком',
        descriptions: {
          signedCertificate: 'Загрузите акт, подписанный с двух сторон',
          additionalAgreement: 'Загрузите дополнительное соглашение',
        },
      },
      destinationCountryDocs: {
        title: 'Загрузите документы для страны назначения',
        descriptions: {
          exportInvoice: 'Загрузите инвойс для экспорта 4%',
          customerCommercialInvoice: 'Загрузите коммерческий инвойс для покупателя',
          customerSpecification: 'Загрузите спецификацию для покупателя на бланке нашей компании',
          customerAcceptanceCertificate: 'Акт приема-передачи, подписанный с одной стороны',
          customerAdditionalAgreement: 'Дополнительное соглашение для покупателя',
        },
      },
      destinationCountryAwb: {
        title: 'Загрузите AWB для страны назначения',
        description: 'Загрузите AWB для страны назначения и нажмите “Подтвердить”',
      },
      transitCountryCargoShipment: {
        title: 'Отгрузка груза',
        description: 'Когда груз будет отправлен из страны транзита, подтвердите отгрузку',
      },
      destinationCountryCargoArrival: {
        title: 'Прибытие груза',
        description: 'Когда груз прибудет в страну назначения, подтвердите прибытие',
      },
    },
    customer: {
      cargoArrival: {
        title: 'Ожидание прибытия груза',
        description: 'Дождитесь, пока менеджер отметит прибытие груза в страну назначения',
      },
      destinationCountryDocs: {
        title: 'Ожидайте загрузку документов по сделке',
        waitDescription: 'Когда документы будут загружены, они появятся здесь',
        approveDescription: 'Документы загружены',
        docsDescription: {
          [DocumentFileType.customerCommercialInvoice]: 'Инвойс',
          [DocumentFileType.customerSpecification]: 'Спецификация',
          [DocumentFileType.customerAcceptanceCertificate]: 'Акт-приема передачи, подписанный с одной стороны',
          [DocumentFileType.customerAdditionalAgreement]: 'Дополнительное соглашение',
        },
      },
      destinationCountryAwb: {
        title: 'Ожидайте загрузку AWB',
        waitDescription: 'Когда AWB будет загружен, он появится здесь',
        approveDescription: 'Менеджер загрузил AWB',
      },
      transfer: {
        title: 'Груз прибыл',
      },
    },
  },
};
