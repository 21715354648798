import { HistoryAction } from '@/entities/tradeHistory';

import {
  ManagerAwbVerifyingAction,
  ManagerConfirmationStep,
  ManagerConfirmationVerifyingAction,
  ManagerCreationStep,
  ManagerFinalizationStep,
  ManagerPaymentStep,
  ManagerShipmentStep,
  ManagerSpecificationVerifyingAction,
  ManagerTransactionAction,
  ManagerTransportationStep,
  OptionFieldsAction,
} from '../../stage';

export const tradeHistory = {
  en: {
    manager: {
      [ManagerCreationStep.createTrade]: 'Trade № {{tradeId}} created',

      [ManagerConfirmationStep.deliveryConfirmation]: 'Delivery confirmation uploaded',
      [ManagerConfirmationStep.confirmationSigning]: 'Delivery confirmation signed',

      [ManagerConfirmationVerifyingAction.approveConfirmationVerifying]: 'Delivery confirmation marked correct',
      [ManagerConfirmationVerifyingAction.rejectConfirmationVerifying]: 'Delivery confirmation marked incorrect',

      [ManagerPaymentStep.uploadBill]: 'Supplier invoice uploaded',
      [ManagerPaymentStep.payBill]: 'The invoice is paid',

      [ManagerShipmentStep.uploadSpec]: 'Supplier specification uploaded',
      [ManagerShipmentStep.shipmentType]: 'Shipment type selected',
      [ManagerShipmentStep.cargoShipment]: 'Confirmed shipment of cargo',
      [ManagerShipmentStep.transitCountry]: 'Transit country selected',
      [ManagerShipmentStep.transitCountryAwb]: 'AWB for transit country uploaded',
      [ManagerShipmentStep.destinationCountryDocs]: 'Documents for destination country uploaded',
      [ManagerShipmentStep.destinationCountryAwb]: 'AWB for destination country uploaded',

      [ManagerSpecificationVerifyingAction.approveSpecification]: 'Specification marked correct',
      [ManagerSpecificationVerifyingAction.rejectSpecification]: 'Specification marked incorrect',
      [ManagerAwbVerifyingAction.approveAwb]: 'AWB marked correct',
      [ManagerAwbVerifyingAction.rejectAwb]: 'AWB marked incorrect',

      [ManagerTransportationStep.transitCountryCargoArrival]: 'Arrival of cargo in transit country confirmed',
      [ManagerTransportationStep.cargoArrival]: 'Arrival of cargo in destination countryconfirmed',
      [ManagerTransportationStep.tradeCloseSupplierDocs]: 'Documents for closing trade with supplier uploaded',
      [ManagerTransportationStep.destinationCountryDocs]: 'Documents for destination country uploaded',
      [ManagerTransportationStep.destinationCountryAwb]: 'AWB for destination country uploaded',
      [ManagerTransportationStep.transitCountryCargoShipment]: 'Shipment of cargo from transit country confirmed',
      [ManagerTransportationStep.destinationCountryCargoArrival]: 'Arrival of cargo in destination country confirmed',

      [ManagerFinalizationStep.cargoDelivered]: 'Cargo delivery confirmed',
      [ManagerFinalizationStep.tradeCloseCustomerDocs]: 'Documents for closing trade with customer uploaded',
      [ManagerTransactionAction.addTransaction]: 'Payment transaction completed',
      [ManagerTransactionAction.deleteTransaction]: 'Payment transaction deleted',
      [ManagerTransactionAction.finish]: 'The deal is completed',

      [OptionFieldsAction.deleteSupplierSpec]: 'Supplier specification deleted',
      [OptionFieldsAction.setDestinationLogisticCompany]: 'A logistics company in the destination country selected',
      [OptionFieldsAction.setTransitLogisticCompany]: 'A logistics company in the transit country selected',
      [OptionFieldsAction.setTrackingLink]: 'Tracking link added',
      [OptionFieldsAction.uploadFinalizationPhoto]: 'Photo uploaded',
      [OptionFieldsAction.uploadAuthorizationLetter]: 'Authorization letter uploaded',
      [OptionFieldsAction.uploadIndirectTaxesPaymentCertificate]: 'Indirect taxes payment certificate uploaded',
      [OptionFieldsAction.uploadWarrantyLoa]: 'Warranty LOA uploaded',

      [HistoryAction.transferToPayment]: 'The deal has been moved to the "Payment" stage',
      [HistoryAction.transferToShipment]: 'The deal has been moved to the "Shipment" stage',
      [HistoryAction.transferToTransportation]: 'The deal has been moved to the "Transportation" stage',
      [HistoryAction.transferToFinalization]: 'The deal has been moved to the "Finalization" stage',
      [HistoryAction.cargoDirectShipmentConfirm]: 'Shipment of cargo to the destination country confirmed',
      [HistoryAction.cargoTransitShipmentConfirm]: 'Shipment of cargo to the transit country confirmed',
    },
    supplier: {
      [HistoryAction.transferToTransportation]: 'The deal has been moved to the "Completing the deal" stage.',
      [HistoryAction.transferToFinalization]: 'The manager marked the deal as completed',
    },
  },
  ru: {
    manager: {
      [ManagerCreationStep.createTrade]: 'Создана сделка № {{tradeId}}',

      [ManagerConfirmationStep.deliveryConfirmation]: 'Загружено подтверждение поставки',
      [ManagerConfirmationStep.confirmationSigning]: 'Подписано подтверждение поставки',

      [ManagerConfirmationVerifyingAction.approveConfirmationVerifying]: 'Подтверждение поставки отмечено верным',
      [ManagerConfirmationVerifyingAction.rejectConfirmationVerifying]: 'Подтверждение поставки отмечено ошибочным',

      [ManagerPaymentStep.uploadBill]: 'Загружен счет от поставщика',
      [ManagerPaymentStep.payBill]: 'Счет оплачен',

      [ManagerShipmentStep.uploadSpec]: 'Загружена спецификация поставщика',
      [ManagerShipmentStep.shipmentType]: 'Выбран тип отгрузки',
      [ManagerShipmentStep.cargoShipment]: 'Подтверждена отгрузка груза',
      [ManagerShipmentStep.transitCountry]: 'Выброна страна транзита',
      [ManagerShipmentStep.transitCountryAwb]: 'Загружен AWB для страны транзита',
      [ManagerShipmentStep.destinationCountryDocs]: 'Загружены документы для страны назначения',
      [ManagerShipmentStep.destinationCountryAwb]: 'Загружен AWB для страны назначения',

      [ManagerSpecificationVerifyingAction.approveSpecification]: 'Спецификация отмечена верной',
      [ManagerSpecificationVerifyingAction.rejectSpecification]: 'Спецификация отмечена ошибочной',
      [ManagerAwbVerifyingAction.approveAwb]: 'AWB отмечен верным',
      [ManagerAwbVerifyingAction.rejectAwb]: 'AWB отмечен ошибочным',

      [ManagerTransportationStep.transitCountryCargoArrival]: 'Подтверждено прибытие груза в страну транзита',
      [ManagerTransportationStep.cargoArrival]: 'Подтверждено прибытие груза в страну назначения',
      [ManagerTransportationStep.tradeCloseSupplierDocs]: 'Загружены закрывающие документы по сделке для поставщика',
      [ManagerTransportationStep.destinationCountryDocs]: 'Загружены документы для страны назначения',
      [ManagerTransportationStep.destinationCountryAwb]: 'Загружен AWB для страны назначения',
      [ManagerTransportationStep.transitCountryCargoShipment]: 'Подтверждена отгрузка груза из страны транзита',
      [ManagerTransportationStep.destinationCountryCargoArrival]: 'Подтверждено прибытие груза в страну назначения',

      [ManagerFinalizationStep.cargoDelivered]: 'Подтверждена доставка груза',
      [ManagerFinalizationStep.tradeCloseCustomerDocs]: 'Загружены документы для закрытия сделки с покупателем',
      [ManagerTransactionAction.addTransaction]: 'Произведена транзакция оплаты',
      [ManagerTransactionAction.deleteTransaction]: 'Произведено удаление транзакции оплаты',
      [ManagerTransactionAction.finish]: 'Сделка завершена',

      [OptionFieldsAction.deleteSupplierSpec]: 'Удалена спецификация поставщика',
      [OptionFieldsAction.setDestinationLogisticCompany]: 'Установлена логистическая компания в стране назначения',
      [OptionFieldsAction.setTransitLogisticCompany]: 'Установлена логистическая компания в стране транзита',
      [OptionFieldsAction.setTrackingLink]: 'Указана ссылка для отслеживания',
      [OptionFieldsAction.uploadFinalizationPhoto]: 'Загружена фотография',
      [OptionFieldsAction.uploadAuthorizationLetter]: 'Загружено авторизационное письма',
      [OptionFieldsAction.uploadIndirectTaxesPaymentCertificate]: 'Загружена справка об уплате косвенных налогов',
      [OptionFieldsAction.uploadWarrantyLoa]: 'Загружена доверенность LOA',

      [HistoryAction.transferToPayment]: 'Сделка переведена на этап "Оплата"',
      [HistoryAction.transferToShipment]: 'Сделка переведена на этап "Отгрузка"',
      [HistoryAction.transferToTransportation]: 'Сделка переведена на этап "Перевозка"',
      [HistoryAction.transferToFinalization]: 'Сделка переведена на этап "Завершение сделки"',
      [HistoryAction.cargoDirectShipmentConfirm]: 'Подтверждена отгрузка груза в страну назначения',
      [HistoryAction.cargoTransitShipmentConfirm]: 'Подтверждена отгрузка груза в страну транзита',
    },
    supplier: {
      [HistoryAction.transferToTransportation]: 'Сделка переведена на этап "Завершение сделки"',
      [HistoryAction.transferToFinalization]: 'Менеджер отметил сделку завершенной',
    },
  },
};
