import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { action, computed, makeObservable, observable } from 'mobx';

import { UserType } from '@/entities/user';
import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';
import { ListModel, LocalStore } from '@/shared/model';
import { UploadFileModel } from '@/shared/model/form/UploadFileModel';
import { Nullable } from '@/shared/types/values';
import { fileSizeValidator } from '@/shared/utils/validators';

import { DocumentFileType, UpdateDocumentPayload, UploadDocumentsPayload } from '../types';

import { ArbitraryDocOptionsModel } from './ArbitraryDocOptionsModel';

export type ArbitraryDocsFormModelParams = {
  arbitraryDocs: UploadFileModel;
  disabled: boolean;
  tradeWorkflowStore: ITradeWorkflowStore;
};

export class ArbitraryDocsFormModel extends LocalStore {
  readonly arbitraryDocs: UploadFileModel;

  readonly disabled: boolean;

  readonly tradeWorkflowStore: ITradeWorkflowStore;

  protected _arbitraryDocsOptions = new ListModel<ArbitraryDocOptionsModel>();
  private _originDocs: UploadFile[];

  constructor({ arbitraryDocs, disabled, tradeWorkflowStore }: ArbitraryDocsFormModelParams) {
    super();

    this._originDocs = tradeWorkflowStore.tradeInfo.arbitraryDocuments ?? [];
    this.arbitraryDocs = arbitraryDocs;
    this.disabled = disabled;
    this.tradeWorkflowStore = tradeWorkflowStore;

    makeObservable<this, '_originDocs' | '_arbitraryDocsOptions'>(this, {
      _originDocs: observable,
      _arbitraryDocsOptions: observable,

      originDocs: computed,
      arbitraryDocsOptions: computed,

      reset: action.bound,
      validate: action.bound,
      onChange: action.bound,
      onRemove: action.bound,
      onChangeOriginDocs: action.bound,
    });
  }

  get arbitraryDocsOptions(): ListModel<ArbitraryDocOptionsModel> {
    return this._arbitraryDocsOptions;
  }

  get originDocs(): UploadFile[] {
    return this._originDocs;
  }

  get isError(): boolean {
    return (
      this.arbitraryDocs.isError ||
      this.arbitraryDocs.value.some((file) => file.error) ||
      this.arbitraryDocsOptions.items.some((item) => item.isError)
    );
  }

  reset(): void {
    this.arbitraryDocs.reset();
    this._arbitraryDocsOptions.reset();
  }

  onRemove(file: UploadFile) {
    const fileList = this.arbitraryDocs.value.filter((f) => f.uid !== file.uid);
    this.arbitraryDocs.change(fileList);
    this.arbitraryDocsOptions.removeEntity(file.uid);
  }

  onChange({ file, fileList }: UploadChangeParam) {
    fileList = fileList.map((file) => {
      const error = fileSizeValidator([file]);

      return {
        ...file,
        error,
        status: error ? 'error' : 'done',
      };
    });

    this.arbitraryDocs.change(fileList);
    this._arbitraryDocsOptions.addEntity({
      entity: new ArbitraryDocOptionsModel({ rootStore: this.tradeWorkflowStore.rootStore }),
      key: file.uid,
    });
  }

  onChangeOriginDocs(fileList: UploadFile[]) {
    this._originDocs = fileList;
    this.arbitraryDocs.reset();
    this.arbitraryDocsOptions.reset();
  }

  validate(): boolean {
    this.arbitraryDocs.validate();
    this.arbitraryDocsOptions.items.forEach((item) => item.validate());

    if (this.isError) {
      const docOptions = this.arbitraryDocsOptions.items.find((item) => item.isError);

      docOptions && docOptions.scrollToOptions();
    }

    return this.isError;
  }

  toUploadJson(): Nullable<UploadDocumentsPayload> {
    const doc_types: Array<DocumentFileType> = [];
    const doc_descriptions: Array<string> = [];
    const visibility: Array<Array<UserType>> = [];

    const files: Array<File> = this.arbitraryDocs.value.reduce<Array<File>>((files, file) => {
      const options = this.arbitraryDocsOptions.getEntity(file.uid);

      if (options && file.originFileObj) {
        files.push(file.originFileObj);
        doc_types.push(options.docCustomType.value as DocumentFileType);
        doc_descriptions.push(options.docComment.value);
        visibility.push(options.docVisibility.value);
      }

      return files;
    }, []);

    if (files.length === 0 || doc_types.length === 0) {
      return null;
    }

    return {
      files,
      doc_types,
      ...(doc_descriptions.length ? { doc_descriptions } : {}),
      ...(visibility.length ? { visibility } : {}),
    };
  }

  toUpdateJson(): Nullable<UpdateDocumentPayload> {
    const json = this.toUploadJson();

    return json
      ? {
          file: json.files[0],
          description: json.doc_descriptions && json.doc_descriptions[0],
          visibility: json.visibility && json.visibility[0],
        }
      : null;
  }

  static fromJson(tradeWorkflowStore: ITradeWorkflowStore, disabled: boolean = false): ArbitraryDocsFormModel {
    return new ArbitraryDocsFormModel({
      arbitraryDocs: new UploadFileModel({
        initialValue: [],
        maxCount: 10,
        disabled,
      }),
      disabled,
      tradeWorkflowStore,
    });
  }
}
