import { ApiListResponse } from '@/shared/types/api';

/**
 * @field admin - Администратор
 * @field agent - Агент
 * @field customer - Покупатель
 * @field manager - Менеджер
 * @field supplier - Поставщик
 */
export enum UserType {
  admin = 'administrator',
  agent = 'agent',
  customer = 'customer',
  manager = 'manager',
  supplier = 'supplier',
}

export type UserBaseServer<T extends UserType> = {
  type: T;
  id: number;
  email: string;
  phone: string;
  name: string;
  address: string;
  // Статус принятия заявки
  accepted: boolean;
  // Кол-во новых уведомлений
  new_notifications_count: number;
};

export type AdminServer = UserBaseServer<UserType.admin>;

export type AgentServer = UserBaseServer<UserType.agent>;

export type ManagerServer = UserBaseServer<UserType.manager>;

export type CustomerServer = UserBaseServer<UserType.customer>;

export type SupplierServer = UserBaseServer<UserType.supplier>;

export type ClientServer = CustomerServer | SupplierServer;

export type UserServer = AdminServer | AgentServer | ClientServer | ManagerServer;

export type ClientsListResponse = ApiListResponse<CustomerServer | SupplierServer>;

export type CreateUserPayload = {
  email: string;
  phone: string;
  type: UserType.customer | UserType.supplier;
  company: {
    name: string;
    address: string;
  };
};

export type EditUserPayload = {
  phone: string;
  company: {
    name: string;
    address: string;
  };
};
