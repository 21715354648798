import { Flex, StepProps } from 'antd';
import * as React from 'react';

import { useWidth } from '@/shared/hooks/useWidth';
import { TranslationNode } from '@/shared/types/localization';

import { TransComp } from '../TransComp';

import StageContentDesktop from './StageContentDesktop';
import StageContentTablet from './StageContentTablet';
import StageSteps from './StageSteps';

import s from './StageContent.module.scss';

export type CustomStepProps = Omit<StepProps, 'title'> & {
  title?: TranslationNode;
};

type StageContentProps = {
  className?: string;
  currentStep: number;
  items: Array<CustomStepProps>;
  extraItem?: React.ReactNode;
  children?: React.ReactNode;
  asSingleStep?: boolean;
};

const StageContent: React.FC<StageContentProps> = ({
  className,
  currentStep,
  items,
  extraItem,
  asSingleStep = false,
  children,
}) => {
  const { isDesktop } = useWidth();

  const itemsList = React.useMemo(() => {
    return items.map((item) => ({
      ...item,
      title: <TransComp>{item.title}</TransComp>,
    }));
  }, [items]);

  const requiredSteps = React.useMemo(
    () => (
      <Flex className={s.stageSteps_wrapper} vertical>
        <StageSteps currentStep={currentStep} items={itemsList} asSingleStep={asSingleStep} />
        {extraItem}
      </Flex>
    ),
    [currentStep, itemsList],
  );

  return isDesktop ? (
    <StageContentDesktop className={className} requiredSteps={requiredSteps} optionalSteps={children} />
  ) : (
    <StageContentTablet className={className} requiredSteps={requiredSteps} optionalSteps={children} />
  );
};

export default StageContent;
