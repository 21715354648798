export const clients = {
  en: {
    title: 'Clients',
    actions: {
      addClient: 'Add a client',
    },
    colums: {
      name: 'Name',
      telephone: 'Phone number',
      email: 'Email',
      address: 'Address',
      accepted: 'Status',
      type: 'Type',
    },
    modal: {
      type: 'Type',
      customer: 'Customer',
      supplier: 'Supplier',
      titleCompany: 'Company',
      titleProfile: 'Profile',
    },
    add: {
      title: 'Adding a client',
      add: 'Add',
      cancel: 'Cancel',
    },
    edit: {
      title: 'Client Profile',
      add: 'Save changes',
      cancel: 'Reset changes',
    },
    messages: {
      editSuccess: 'Client updated successfully',
      editError: 'An error occurred while updating the client',
      addSuccess: 'Client added successfully',
      addError: 'An error occurred while adding a client',
    },
  },
  ru: {
    title: 'Клиенты',
    actions: {
      addClient: 'Добавить клиента',
    },
    colums: {
      name: 'Название',
      telephone: 'Номер телефона',
      email: 'Email',
      address: 'Адрес',
      accepted: 'Статус',
      type: 'Тип',
    },
    modal: {
      type: 'Тип',
      customer: 'Покупатель',
      supplier: 'Поставщик',
      titleCompany: 'Компания',
      titleProfile: 'Профиль',
    },
    add: {
      title: 'Добавление клиента',
      add: 'Добавить',
      cancel: 'Отмена',
    },
    edit: {
      title: 'Профиль клиента',
      add: 'Сохранить изменения',
      cancel: 'Сбросить изменения',
    },
    messages: {
      editSuccess: 'Клиент успешно обновлен',
      editError: 'Произошла ошибка при обновлении клиента',
      addSuccess: 'Клиент успешно добавлен',
      addError: 'Произошла ошибка при добавлении клиента',
    },
  },
};
