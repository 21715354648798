import { SupportedLocale } from '../types/localization';

export const shared = {
  en: {
    date: {
      today: 'Today',
      yesterday: 'Yesterday',
      tomorrow: 'Tomorrow',
    },
    placeholders: {
      search: 'Search',
      select: 'Select from the directory',
      date: 'Specify the date',
    },
    buttons: {
      add: 'Add',
      approve: 'Approve',
      apply: 'Apply',
      delete: 'Delete',
      upload: 'Upload',
      reset: 'Reset',
      cancel: 'Cancel',
      generate: 'Generate',
      create: 'Create',
    },
    validationErrors: {
      buyPriceUnit: 'Enter buy price unit',
      email: 'Enter correct email',
      paymentUnit: 'Enter payment unit',
      phoneNumber: 'Enter correct phone number',
      required: 'Field is required',
      sellPriceUnit: 'Enter sell price unit',
      weightUnit: 'Enter weight unit',
      fileSize: 'File should not be larger than {{ fileSize }}\u00A0MB',
      stringLength: 'Value should not be longer than {{ length }}\u00A0characters',
    },
    components: {
      LanguageSelector: {
        labels: {
          [SupportedLocale.ru]: 'Russian',
          [SupportedLocale.en]: 'English',
        },
      },
      UploadDragger: {
        title: 'Drop files here or click to upload',
        subTitle: 'File should not be larger than 100\u00A0MB',
      },
      OfflineStatus: {
        title: 'Check your internet connection',
        subTitle: 'Check your Wi-Fi or mobile network and try again',
        button: 'Retry',
      },
      EmptySearchStub: {
        description: 'No data found',
        button: 'Reset search',
      },
      StageContentDesktop: {
        title: 'Current step',
      },
      StageContentTablet: {
        labels: {
          required: 'Required steps',
          optional: 'Optional steps',
        },
      },
      FormItem: {
        optional: 'optional',
      },
    },
    userHandling: {
      name: 'Name',
      phoneNumber: 'Phone number',
      address: 'Address',
    },
  },
  ru: {
    date: {
      today: 'Сегодня',
      yesterday: 'Вчера',
      tomorrow: 'Завтра',
    },
    placeholders: {
      search: 'Найти',
      select: 'Выберите из справочника',
      date: 'Укажите дату',
    },
    buttons: {
      add: 'Добавить',
      approve: 'Подтвердить',
      apply: 'Применить',
      delete: 'Удалить',
      upload: 'Загрузить',
      reset: 'Сбросить',
      cancel: 'Отменить',
      generate: 'Сгенерировать',
      create: 'Создать',
    },
    validationErrors: {
      buyPriceUnit: 'Укажите валюту покупки',
      email: 'Введён некорректный email',
      paymentUnit: 'Укажите валюту платежа',
      phoneNumber: 'Введён неверный формат номера',
      required: 'Это обязательное поле',
      sellPriceUnit: 'Укажите валюту продажи',
      weightUnit: 'Укажите единицу измерения веса',
      fileSize: 'Файл не должен превышать {{ fileSize }}\u00A0MB',
      stringLength: 'Значение не должно превышать {{ length }}\u00A0символов',
    },
    components: {
      LanguageSelector: {
        labels: {
          [SupportedLocale.ru]: 'Русский',
          [SupportedLocale.en]: 'Английский',
        },
      },
      UploadDragger: {
        title: 'Перетащите файлы сюда или нажмите, чтобы загрузить',
        subTitle: 'Файл не должен быть больше 100\u00A0MB',
      },
      OfflineStatus: {
        title: 'Проверьте подключение к интернету',
        subTitle: 'Проверьте подключение к Wi-Fi или мобильной сети и повторите попытку',
        button: 'Повторить попытку',
      },
      EmptySearchStub: {
        description: 'Ничего не найдено',
        button: 'Сбросить поиск',
      },
      StageContentDesktop: {
        title: 'Текущий шаг',
      },
      StageContentTablet: {
        labels: {
          required: 'Обязательные шаги',
          optional: 'Необязательные шаги',
        },
      },
      FormItem: {
        optional: 'oпционально',
      },
    },
    userHandling: {
      name: 'Название',
      phoneNumber: 'Номер телефона',
      address: 'Адрес',
    },
  },
};
