import { DownOutlined } from '@ant-design/icons';
import { useToggleState } from '@kts-front/hooks';
import { Flex, Select } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';

import { LanguageSelectorCallback } from '../../types';

import { mapLangToFlag } from './config';

import s from './LanguageSelector.module.scss';

type LanguageSelectorProps = {
  className?: string;
  isMenuOpened: boolean;
  callbackRef: React.MutableRefObject<LanguageSelectorCallback | null>;
};

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ className, isMenuOpened, callbackRef }) => {
  const { localizationStore } = useRootStore();
  const { t, lng, langs, changeLng } = localizationStore;
  const { opened, toggle, close } = useToggleState();

  React.useImperativeHandle(
    callbackRef,
    () => ({
      toggleLanguageSelector: toggle,
    }),
    [],
  );

  React.useEffect(() => {
    close();
  }, [lng, isMenuOpened]);

  const options = React.useMemo(() => {
    return langs.map((lng) => ({
      value: lng,
      label: (
        <Flex align="center" gap={8}>
          {mapLangToFlag[lng]}
          {t(`components.LanguageSelector.labels.${lng}`, { ns: 'shared' })}
        </Flex>
      ),
    }));
  }, [t, langs]);

  return (
    <>
      <Select
        open={opened}
        className={cn(s.languageSelector, !isMenuOpened && s.languageSelector_closed, className)}
        options={options}
        defaultValue={lng}
        onChange={changeLng}
        labelRender={() => null}
      />
      <Flex align="center" gap={8}>
        {t(`components.LanguageSelector.labels.${lng}`, { ns: 'shared' })}
        {isMenuOpened && <DownOutlined />}
      </Flex>
    </>
  );
};

export default observer(LanguageSelector);
