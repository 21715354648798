import { TaxType } from '@/entities/company';

export const company = {
  en: {
    fields: {
      name: 'Name of legal entity',
      address: 'Address',
      email: 'Email',
      phone: 'Phone number',
      tax: 'Tax sign',
      country: 'Country',
      selectFormCountry: 'Select from the list',
    },
    taxName: {
      [TaxType.BR]: 'BR',
      [TaxType.CR]: 'CR',
      [TaxType.TIN]: 'TIN',
      [TaxType.TRN]: 'TRN',
      [TaxType.certificateNumber]: 'Certificate number',
      [TaxType.licenceNumber]: 'Licence number',
    },
  },
  ru: {
    fields: {
      name: 'Название юридического лица',
      address: 'Адрес',
      email: 'Email',
      phone: 'Номер телефона',
      tax: 'Налоговый признак',
      country: 'Страна',
      selectFormCountry: 'Выберите из списка',
    },
    taxName: {
      [TaxType.BR]: 'BR',
      [TaxType.CR]: 'CR',
      [TaxType.TIN]: 'TIN',
      [TaxType.TRN]: 'TRN',
      [TaxType.certificateNumber]: 'Номер сертификата',
      [TaxType.licenceNumber]: 'Номер лицензии',
    },
  },
};
