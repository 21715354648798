import { StageType } from '@/entities/stage';
import { TradeStatus } from '@/entities/trade';

export const trades = {
  en: {
    title: 'Trades',
    stageStatus: {
      [StageType.creation]: 'Creation',
      [StageType.confirmation]: 'Confirmation',
      [StageType.payment]: 'Payment',
      [StageType.shipment]: 'Shipment',
      [StageType.transportation]: 'Transportation',
      [StageType.finalization]: 'Completing the deal',
      [TradeStatus.finished]: 'The deal is completed',
    },
    columns: {
      number: 'Number',
      product: 'Metal',
      weight: 'Weight',
      amount: 'Trade amount',
      customerPayment: 'Customer payment',
      fixingDate: 'Fixing date',
      transitCountryCargoArrivalDate: 'Arrival in transit',
      transitCountryCargoShipmentDate: 'Departure from transit',
      destinationCountryCargoArrivalDate: 'Arrival at the arrival airport',
      cargoDeliveredDate: 'Delivery to the customer',
      paymentDate: 'Payment time',
      customer: 'Customer',
      supplier: 'Supplier',
      status: 'Status',
    },
    actions: {
      download: 'Download list',
      create: 'Create trade',
    },
    createModal: {
      title: 'Create trade',
      okText: 'Save',
    },
    deleteModal: {
      title: 'Confirm deletion of trade №\u00A0{{tradeId}}',
      okText: 'Delete',
      deleteSuccess: 'The trade successfully deleted',
      deleteError: 'An error occurred while deleting the trade',
    },
  },
  ru: {
    title: 'Сделки',
    stageStatus: {
      [StageType.creation]: 'Создание сделки',
      [StageType.confirmation]: 'Подтверждение',
      [StageType.payment]: 'Оплата',
      [StageType.shipment]: 'Отгрузка',
      [StageType.transportation]: 'Перевозка',
      [StageType.finalization]: 'Завершение сделки',
      [TradeStatus.finished]: 'Сделка завершена',
    },
    columns: {
      number: 'Номер сделки',
      product: 'Металл',
      weight: 'Вес',
      amount: 'Сумма сделки',
      customerPayment: 'Оплата покупателя',
      fixingDate: 'Дата фиксинга',
      transitCountryCargoArrivalDate: 'Прилет в транзит',
      transitCountryCargoShipmentDate: 'Вылет из транзита',
      destinationCountryCargoArrivalDate: 'Прилет в аэропорт прибытия',
      cargoDeliveredDate: 'Доставка покупателю',
      paymentDate: 'Время оплаты',
      customer: 'Покупатель',
      supplier: 'Поставщик',
      status: 'Статус',
    },
    actions: {
      download: 'Скачать список',
      create: 'Создать сделку',
    },
    createModal: {
      title: 'Создание сделки',
      okText: 'Сохранить',
    },
    deleteModal: {
      title: 'Подтвердите удаление сделки №\u00A0{{tradeId}}',
      okText: 'Удалить',
      deleteSuccess: 'Сделка успешно удалена',
      deleteError: 'Произошла ошибка при удалении сделки',
    },
  },
};
