import { Flex } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import FormDatePicker from '@/shared/ui/form/FormDatePicker';
import FormInput from '@/shared/ui/form/FormInput';
import FormSelect from '@/shared/ui/form/FormSelect';

import { AdditionalAgreementFiedsModel } from '../../model/fieldsModel/AdditionalAgreementModel';

type AdditionalAgreementFormProps = {
  model: AdditionalAgreementFiedsModel;
};

const AdditionalAgreementForm: React.FC<AdditionalAgreementFormProps> = ({ model }) => {
  const { date, signatureId, invoiceNumber, invoiceDate, debtRepaymentDate, loadingState } = model;

  const { dictionariesStore } = useRootStore();

  return (
    <Flex vertical gap={16}>
      <FormDatePicker model={date} disabled={loadingState.isLoading} />
      <FormSelect
        model={signatureId}
        options={dictionariesStore.signatories.list.items}
        disabled={loadingState.isLoading}
      />
      <FormInput model={invoiceNumber} disabled={loadingState.isLoading} />
      <FormDatePicker model={invoiceDate} disabled={loadingState.isLoading} />
      <FormDatePicker model={debtRepaymentDate} disabled={loadingState.isLoading} />
    </Flex>
  );
};

export default observer(AdditionalAgreementForm);
