import { SelectProps } from 'antd/es/select';

const ckeckValueAsStringOrNumber = (value: React.ReactNode): value is string | number => {
  return typeof value === 'string' || typeof value === 'number';
};

export const filterOption: SelectProps['filterOption'] = (input, option) => {
  return ckeckValueAsStringOrNumber(option?.label) && String(option.label).toLowerCase().includes(input.toLowerCase());
};

export const filterSort: SelectProps['filterSort'] = (optionA, optionB) => {
  return ckeckValueAsStringOrNumber(optionA.label) && ckeckValueAsStringOrNumber(optionB.label)
    ? String(optionA.label).toLowerCase().localeCompare(String(optionB.label).toLowerCase())
    : 0;
};
