import { observer } from 'mobx-react-lite';
import React from 'react';

import { taxTypeOptions } from '@/entities/company';
import { CustomSelect } from '@/shared/ui/form/CustomSelect';
import FormInput from '@/shared/ui/form/FormInput';
import FormSelect from '@/shared/ui/form/FormSelect';
import PhoneInput from '@/shared/ui/form/PhoneInput';

import { CompanyEditFieldsModel, CompanyFieldsModel } from '../../model';

import s from './CompanyFields.module.scss';

type Props = {
  fields: CompanyFieldsModel | CompanyEditFieldsModel;
};

const CompanyFields: React.FC<Props> = ({ fields }) => {
  if (fields instanceof CompanyEditFieldsModel) {
    return (
      <>
        <FormSelect model={fields.countryId} options={fields.countryOptions} />
        <FormInput model={fields.address} />
        <FormInput model={fields.email} />
        <PhoneInput model={fields.phone} />
        <FormInput
          model={fields.taxValue}
          addonBefore={
            <CustomSelect
              className={s.select}
              popupClassName={s.select_popup}
              value={fields.taxType.value}
              onChange={fields.taxType.change}
              options={taxTypeOptions}
            />
          }
        />
      </>
    );
  }

  return (
    <>
      <FormInput size="large" model={fields.name} />
      <FormInput size="large" model={fields.address} />
    </>
  );
};

export default observer(CompanyFields);
