import cn from 'classnames';
import * as React from 'react';

import { Loader } from '../Loader';

import CrossComp from './CrossComp';

import s from './InfiniteScroll.module.scss';

type InfiniteScrollProps = React.PropsWithChildren<{
  className?: string;
  isUpLoading?: boolean;
  isDownLoading?: boolean;
  hasMore?: boolean;
  scrollUpAction?: () => Promise<unknown>;
  scrollDownAction?: () => Promise<unknown>;
}>;

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  className,
  children,
  hasMore,
  isUpLoading,
  isDownLoading,
  scrollUpAction,
  scrollDownAction,
}) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);

  return (
    <div className={cn(s['infinite-scroll'], className)} ref={scrollRef}>
      {scrollUpAction && (
        <>
          <CrossComp
            omitInitialLoad
            hasMore={hasMore}
            scrollAction={scrollUpAction}
            options={{ root: scrollRef?.current, threshold: 0, rootMargin: '100px 0px 0px' }}
          />
          {isUpLoading && <Loader className={s.loader} size={24} />}
        </>
      )}
      {children}
      {scrollDownAction && (
        <>
          {isDownLoading && <Loader className={s.loader} size={24} />}
          <CrossComp
            hasMore={hasMore}
            scrollAction={scrollDownAction}
            options={{ root: scrollRef?.current, threshold: 0, rootMargin: '0px 0px 100px' }}
          />
        </>
      )}
    </div>
  );
};

export default InfiniteScroll;
