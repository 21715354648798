import { Input } from 'antd';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks/useTranslation';
import { TranslationString } from '@/shared/types/localization';
import { renderTranslationString } from '@/shared/utils/renderTranslation';

type CustomInputProps = Omit<React.ComponentProps<typeof Input>, 'placeholder'> & {
  placeholder?: TranslationString;
};

const CustomInput: React.FC<CustomInputProps> = ({ placeholder = '', ...props }) => {
  const { t } = useTypedTranslation();

  return <Input {...props} placeholder={renderTranslationString(placeholder, t)} />;
};

export default CustomInput;
