import { UserType } from '../types';

export const user = {
  en: {
    fields: {
      contacts: 'Contact details',
      name: 'Name',
      fio: 'Full name',
      phone: 'Phone number',
      email: 'Email',
      address: 'Address',
      type: 'Type',
    },
    userStatus: {
      active: 'Active',
      notActive: 'Not Active',
    },
    userRoles: {
      [UserType.customer]: 'Customer',
      [UserType.supplier]: 'Supplier',
      [UserType.agent]: 'Agent',
      [UserType.manager]: 'Manager',
      [UserType.admin]: 'Administrator',
    },
  },
  ru: {
    fields: {
      contacts: 'Контактные данные',
      name: 'Название',
      fio: 'ФИО',
      phone: 'Номер телефона',
      email: 'Email',
      address: 'Адрес',
      type: 'Тип',
    },
    userStatus: {
      active: 'Активен',
      notActive: 'Не активен',
    },
    userRoles: {
      [UserType.customer]: 'Покупатель',
      [UserType.supplier]: 'Поставщик',
      [UserType.agent]: 'Агент',
      [UserType.manager]: 'Менеджер',
      [UserType.admin]: 'Администратор',
    },
  },
};
